import Router from "@/router/index"
import core from "@/utils/core"
import Utils from "../../jointly/utils/utils"

export default class Route {
    static jump(url, params = {}) {
        var curr_url = window.location.hash.replace(/#/, "").split("?")[0]
        // 获取所有参数
        const ps = JSON.parse(sessionStorage.getItem("ROUTE_PARAMS") || "{}")
        // 写入当前页面的参数
        ps[curr_url] = Utils.paramsToJson(window.location.hash)
        // 缓存数据
        sessionStorage.setItem("ROUTE_PARAMS", JSON.stringify(ps))
        // 返回的页面名称
        params.cb_url = curr_url
        // 关闭当前页面所以弹窗
        let ws = window["COMP_MODAL_WINDOWS"]
        // 关闭全部
        if (ws?.length > 0) ws[0].v?.closeAll()
        // 跳转
        Router.push(Utils.jsonToParams(params, url))
    }

    static isBack() {
        return !!core.getUrlParam("cb_url")
    }

    static back() {
        // 获取所有参数
        const ps = JSON.parse(sessionStorage.getItem("ROUTE_PARAMS") || "{}")
        var cb = core.getUrlParam("cb_url")
        // 如果没有上一页则跳转到首页
        if (!cb) return Router.push("/homepage")
        // 解码
        cb = decodeURIComponent(cb)
        // 返回上一页
        Router.push(cb + Utils.jsonToParams(ps[cb] || {}))
    }
}
