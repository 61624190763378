<template>
    <div class="elem-map-box" v-show="!modal || isDisplay">
        <div class="map-window-box" :class="{ 'map-window-box-full': expand || isDisplay }">
            <div class="map-input-box">
                <div class="input-box">
                    <input class="input" v-model="input_value" type="text" placeholder="输入地址检索" @input="onInput" @focus="onInputFocus" @blur="onInputBlur" />
                    <block v-if="!modal">
                        <div class="operate-btn" @click="onChangeExpand">
                            <div class="success">{{ expand ? "关闭" : "展开" }}</div>
                        </div>
                        <div class="more-btn" @click="onChangeDisplaySetting">
                            <Icon type="ios-more" size="30" />
                        </div>
                    </block>
                    <block v-else>
                        <div class="operate-btn" @click="onSubmit">
                            <div class="success">提交</div>
                        </div>
                        <div class="more-btn" @click="isDisplay = false">
                            <Icon type="ios-close" size="30" />
                        </div>
                    </block>
                </div>

                <div class="result-box" v-show="display_result && result">
                    <div class="item-box" v-for="(item, idx) in result" :key="idx" @click="onSelectResult(item)">
                        <div class="info-box">
                            <p class="title">{{ item.name }}</p>
                            <!-- <p class="district">{{ item.address }}</p> -->
                        </div>
                        <div class="address">{{ item.address }}</div>
                    </div>
                    <div class="empty" v-if="result && result.length <= 0">暂无搜索结果</div>
                </div>
            </div>
            <div ref="map" class="map-container-box">
                <!-- <bm-view class="map" style="width:100%;height:100%"></bm-view>
                <bm-marker v-if="marker" :position="marker || {}"></bm-marker>
                <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation> -->
            </div>
        </div>

        <!-- 设置弹窗 -->
        <div class="setting-page" v-show="isDisplaySetting">
            <div class="setting-box">
                <div class="setting-title-box">
                    <p class="title">设置</p>
                    <div class="close-btn" @click="onChangeDisplaySetting">
                        <Icon type="md-close" size="20" />
                    </div>
                </div>
                <div class="setting-form-box">
                    <div class="item-box">
                        <div class="name">
                            <p style="margin-right: 5px">仅定位</p>
                            <Tooltip placement="top" max-width="200" content="开启仅定位功能后不会改变输入的地址名称">
                                <Icon type="ios-help-circle-outline" />
                            </Tooltip>
                        </div>
                        <div class="switch-box">
                            <i-switch v-model="only_position" @on-change="onChangeOnlyPosition"></i-switch>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BaiduMap, BmGeolocation, BmView, BmMarker } from "vue-baidu-map"

import Headway from "../utils/headway"

export default {
    data() {
        return {
            // 显示设置页面
            isDisplaySetting: false,
            // 显示页面（modal 模式下）
            isDisplay: false,
            input_value: "",
            display_result: false,
            result: null,
            old_value: null,
            expand: false,
            baiduAk: this.$store.state.baiduAk,
            marker: null,

            only_position: localStorage.getItem("ELEM_MAP_IS_ONLY_POSITION") === "true",
        }
    },

    props: {
        name: String,
        required: {
            type: Boolean,
            default: true,
        },
        title: String,
        address: {
            type: String,
            default: "",
        },
        location: {
            type: Object,
            required: false,
        },
        // 是否为弹窗模式
        modal: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        BaiduMap,
        BmGeolocation,
        BmView,
        BmMarker,
    },

    watch: {
        address: {
            handler(v) {
                if (this._val?.address === v) return

                if (!v) {
                    this.input_value = null
                    this.result = null
                    return
                }

                this.input_value = v

                clearTimeout(this.analyzeAddressTimeout)

                if (!this.location) {
                    this.analyzeAddressTimeout = setTimeout(async () => {
                        if (this.location) return
                        // 根据地址获取经纬度
                        let res = await this.getLocation(v)
                        console.log(res)
                        this.marker = res.location
                    }, 300)
                }
            },
            immediate: true,
        },

        location: {
            handler(v) {
                if (this._val?.location === v) return

                if (v && v.latitude && v.longitude) {
                    if (this.marker?.lat === v.latitude && this.marker?.lng === v.longitude) return
                    this.marker = new T.LngLat(v.longitude, v.latitude)
                } else {
                    this.marker = null
                    this.map?.centerAndZoom(new T.LngLat("113.331084", "23.112223"), 15)
                }
            },
            immediate: true,
        },
        marker: {
            handler(v, o) {
                if (!v) {
                    // 清除覆盖物
                    this.map?.clearOverLays()
                    return
                }

                if (v === o) return

                if (v && v.lat && v.lng) {
                    Headway.of(this, "map")
                        .on()
                        .then(() => {
                            // 跳转到中心点
                            setTimeout(
                                () => {
                                    // 清除覆盖物
                                    this.map.clearOverLays()
                                    // 创建标记
                                    let marker = new T.Marker(v)
                                    // 添加标记
                                    this.map.addOverLay(marker)

                                    let zoom = this.map.getZoom()
                                    this.map.centerAndZoom(v, zoom > 15 ? zoom : 15)
                                },
                                o ? 0 : 500
                            )
                        })
                    this.onChange()
                }
            },
            immediate: true,
        },

        only_position(v) {
            localStorage.setItem("ELEM_MAP_IS_ONLY_POSITION", v)
        },

        input_value() {
            this.onChange()
        },
    },

    mounted() {
        const script = document.createElement("script")
        script.type = "text/javascript"
        script.src = `https://api.tianditu.gov.cn/api?v=4.0&tk=9086bea5df9b127fb5d0d701e5c6310e`
        document.body.appendChild(script)
        var that = this
        if (script.addEventListener) {
            script.addEventListener("load", function () {
                console.log('load');
                that.getMap()
            }, false)
        } else if (script.attachEvent) {
            script.attachEvent("onreadystatechange", function () {
                if (target.readyState == "loaded") {
                    console.log('onreadystatechange');
                    that.getMap()
                }
            })
        }
    },

    methods: {
        getMap() {
            setTimeout(() => {
                this.$nextTick(() => {
                    let meat = document.createElement("meta")
                    meat.name = "referrer"
                    meat.content = "no-referrer"
                    document.head.appendChild(meat)

                    const map = (this.map = new T.Map(this.$refs.map))
                    this.geocoder = new T.Geocoder()
                    // 自动移动到中心点
                    map.centerAndZoom(new T.LngLat("113.331084", "23.112223"), 15)
                    // 搜索对象
                    this.localSearch = new T.LocalSearch(map, {
                        pageCapacity: 10,
                        onSearchComplete: rs => {
                            console.log(rs)
                            if (rs.pois && rs.pois.length > 0) {
                                this.result = rs.pois
                            } else {
                                this.result = null
                            }
                        },
                    })
                    console.log(this.localSearch)

                    map.addEventListener("click", evt => {
                        this.marker = evt.lnglat
                        // 获取位置信息
                        this.geocoder.getLocation(evt.lnglat, rs => {
                            // 赋值位置名称
                            this.input_value = rs.getAddress()
                        })
                    })

                    // 移除
                    document.head.removeChild(meat)
                })
            }, 400)
        },
        display() {
            this.isDisplay = true

            // 通知地图大小变更
            this.$nextTick(() => {
                this.map.checkResize()
            })
        },

        /**
         * 清除
         */
        clear() {
            this.input_value = null
            this.result = null
            this.marker = null
            // 恢复原点
            this.map.centerAndZoom(new T.LngLat("113.331084", "23.112223"), 15)
        },

        onChangeExpand() {
            this.expand = !this.expand

            // 通知地图大小变更
            this.$nextTick(() => {
                this.map.checkResize()
            })
        },

        /**
         * 监听文本框输入事件
         */
        onInput(evt) {
            // 清除延时事件
            clearTimeout(this.inputTimeout)

            // 延时执行
            this.inputTimeout = setTimeout(() => {
                const e = evt.target

                if (e.value) {
                    // 根据关键字进行搜索
                    this.localSearch.search(e.value, 1)
                } else {
                    this.result = null
                }
            }, 500)
        },

        onChange() {
            let marker = this.marker

            this._val = {
                address: this.input_value,
                location: {
                    latitude: marker?.lat,
                    longitude: marker?.lng,
                },
            }

            this.$emit("on-change", {
                tag: "ElemMap",
                value: this._val,
            })
        },

        onInputFocus() {
            setTimeout(() => {
                this.display_result = true
            }, 100)
        },

        onInputBlur() {
            setTimeout(() => {
                this.display_result = false
            }, 300)
        },

        /**
         * 监听选择搜索结果
         */
        onSelectResult(v) {
            this.marker = new T.LngLat(...v.lonlat.split(","))
            !this.only_position && (this.input_value = v.address + v.name)
        },

        getFormValue() {
            var location

            if (this.marker) {
                let ll = this.marker

                location = {
                    latitude: ll.lat,
                    longitude: ll.lng,
                }
            }

            return {
                name: this.name,
                tag: "ElemMap",
                value: {
                    address: this.input_value,
                    location: location,
                },
            }
        },

        /**
         * 地址转为坐标
         */
        getLocation(value) {
            return new Promise((resolve, reject) => {
                this.geocoder.getPoint(value, point => {
                    if (point) {
                        resolve({
                            address: value,
                            location: {
                                lng: point.location.lng,
                                lat: point.location.lat,
                            },
                        })
                    } else {
                        let msg = "无法识别输入的地址，请在地图上选址！"
                        // 显示错误信息
                        // Message.error(msg)
                        reject(msg)
                    }
                })
            })
        },

        onChangeOnlyPosition(evt) {
            this.only_position = evt
        },

        onChangeDisplaySetting() {
            this.isDisplaySetting = !this.isDisplaySetting
        },

        onSubmit() {
            const res = this.getFormValue()

            if (res.value.location) {
                this.$emit("on-submit", res)
                // 关闭
                this.isDisplay = false
                // 清除
                this.$nextTick(() => {
                    this.clear()
                })
            } else {
                this.$Message.error({
                    content: "请选择坐标",
                    background: true,
                })
            }
        },
    },
}
</script>

<style lang="less">
.elem-map-box {
    position: relative;
    height: 300px;
    width: 500px;

    .map-window-box {
        width: 100%;
        height: 100%;

        .map-input-box {
            position: absolute;
            top: 10px;
            left: 10px;
            right: 10px;
            margin: 0 auto;
            max-width: 500px;
            border: 1px solid #e3e4e9;
            background: #fff;
            border-radius: 8px;
            transition: all 0.3s ease;
            overflow: hidden;
            box-sizing: border-box;
            z-index: 10;

            .input-box {
                width: 100%;
                height: 45px;
                display: flex;
                align-items: center;

                .input {
                    width: 100%;
                    padding: 0 110px 0 10px;
                    height: 100%;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    border: 0;
                }

                .operate-btn {
                    position: absolute;
                    top: 0;
                    height: 45px;
                    right: 40px;
                    padding: 5px 0 5px 5px;
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;

                    .success {
                        cursor: pointer;
                        padding: 10px 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #2db7f5;
                        color: #fff;
                        line-height: 1;
                        border-radius: 5px;
                    }
                }

                .more-btn {
                    position: absolute;
                    top: 0;
                    height: 45px;
                    right: 0;
                    margin: 0 5px;
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }

            .result-box {
                width: 100%;
                max-height: 200px;
                overflow: hidden;
                overflow-y: auto;
                border-top: 1px solid #e3e4e9;

                > .item-box {
                    cursor: pointer;
                    padding: 10px 10px;
                    border-bottom: 1px solid #f3f3f3;
                    line-height: 1;

                    &:last-child {
                        border-bottom: initial;
                    }

                    &:hover {
                        background: #f3f3f3;
                    }

                    .info-box {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .title {
                            font-size: 14px;
                            font-weight: bold;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .district {
                            font-size: 12px;
                            color: #888;
                            flex-shrink: 0;
                        }
                    }

                    .address {
                        font-size: 12px;
                        margin-top: 8px;
                        color: #888;
                    }
                }

                .empty {
                    line-height: 40px;
                    font-size: 12px;
                    color: #888;
                    width: 100%;
                    text-align: center;
                }
            }
        }

        .map-container-box {
            position: relative;
            width: 100%;
            height: 100%;
            border: 1px solid #e3e4e9;
            z-index: 5;
            border-radius: 3px;
            transition: all 0.3s ease;

            &:hover {
                border-color: #b3b3b3;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            }

            .anchorTL {
                width: 100%;

                input {
                    padding: 0 10px;
                    margin: 10px;
                    height: 45px;
                    width: ~"calc(100% - 20px)";
                    border: 1px solid #e3e3e3;
                    border-radius: 8px;
                    overflow: hidden;
                    box-sizing: border-box;
                }
            }
        }

        &.map-window-box-full {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 999;

            .map-input-box .result-box {
                max-height: 300px;
            }
        }
    }

    .setting-page {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        padding: 10% 0;
        box-sizing: border-box;
        align-items: flex-start;
        display: flex;
        justify-content: center;
        z-index: 9999;

        .setting-box {
            width: 400px;
            background: #fff;
            border-radius: 10px;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
            padding: 0 20px;

            .setting-title-box {
                position: relative;
                padding: 10px 0;
                border-bottom: 1px solid #e3e3e3;
                display: flex;
                align-items: center;
                justify-content: center;

                .title {
                    font-size: 16px;
                    font-weight: bold;
                }

                .close-btn {
                    position: absolute;
                    top: 10px;
                    right: 0;
                    bottom: 10px;
                    padding: 0 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }

            .setting-form-box {
                padding: 10px 0;

                .item-box {
                    width: 100%;
                    margin: 10px 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .name {
                        display: flex;
                    }
                }
            }
        }
    }
}
</style>
