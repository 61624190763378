class QueueController {
    _list = []

    _run = false

    constructor(queue) {
        this.push(queue)
    }

    push(queue) {
        this._list.push(queue)

        if (!this._run) {
            this.next()
        }
    }

    next() {
        if (this._list.length <= 0) {
            this._run = false
            return
        }

        this._run = true

        this._list[0].run(() => {
            this._list.splice(0, 1)

            // next
            this.next()
        })
    }

    getRunStatus() {
        return this._run
    }
}

export default class Queue {
    static queues = {}

    _callback

    _key

    constructor(key, callback) {
        this._key = key
        this._callback = callback
    }

    run(next) {
        return this._callback(next)
    }

    /**
     * 队列任务
     * @param key key 值
     * @param callback 回调函数
     */
    static line(key, callback) {
        const queues = this.queues

        const queue = new Queue(key, callback)

        if (queues[key]) {
            queues[key].push(queue)
        } else {
            queues[key] = new QueueController(queue)
        }
    }

    /**
     * 单次任务
     * @param key key 值
     * @param callback 回调函数
     */
    static single(key, callback) {
        const queues = this.queues

        const queue = new Queue(key, callback)

        if (!queues[key]) {
            queues[key] = new QueueController(queue)
        } else if (!queues[key].getRunStatus()) {
            queues[key].push(queue)
        }
    }
}
