<template>
    <CompModal class="comp-form-box" ref="comp_model" :title="title || '表单'" :isFull="fullscreen" @on-close="onCloseModal" :height="height" :width="width" :isScroll="isScroll">
        <template #default>
            <CompForm ref="comp_form" :value="formData" v-if="forms" :forms="forms" :isDisplaySubmit="false" :full="fullscreen"></CompForm>
            <div v-else class="container">
                <div class="menu" v-if="menus">
                    <Menu theme="light" :active-name="currentMenu" @on-select="onChangeMenu">
                        <MenuItem v-for="(item, idx) in menus" :name="item.id" :key="'menu-' + idx" v-show="!item.condition || item.condition(formData)">
                            <Icon :type="item.icon || 'ios-information-circle'" />
                            {{ item.title }}
                        </MenuItem>
                    </Menu>
                </div>
                <div class="content-base" :style="{ overflow: isScroll ? 'auto' : 'initial' }">
                    <div class="content-box">
                        <Form :key="form_uuid" :label-colon="true" :label-width="100" :rules="validate" ref="form">
                            <slot name="form-box" :form="formData" :model="model" :menu="currentMenu"></slot>
                        </Form>
                    </div>
                </div>
            </div>
        </template>
        <template #operate="{ close }">
            <div class="operate-btns">
                <Button class="operate-btn" type="info" @click="close">取消</Button>
                <Button class="operate-btn highlight" type="primary" @click="onSubmit">确定</Button>
            </div>
        </template>
    </CompModal>
</template>

<script>
import CompForm from "../../customform/components/CompForm.vue"
import CompModal from "@/views/residentdatabase/components/CompModal"
import Utils from "../utils/utils"

export default {
    components: { CompForm, CompModal },

    data() {
        return {
            formData: {},
            // 菜单
            menus: null,
            // 当前选择的菜单
            currentMenu: null,
            // 当前表单模式
            model: null,
        }
    },

    props: {
        forms: {
            type: Array,
            required: false,
        },
        datas: {
            type: Object,
            required: false,
        },
        // 是否全屏
        fullscreen: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            default: "数据",
        },
        // 提交表单的前置事件
        submitBefore: {
            type: Function,
            required: false,
        },
        // 提交数据接口
        submitApi: String,
        // 修改数据接口
        editApi: {
            type: String,
            required: false,
        },
        // 详情接口
        detailApi: {
            type: String,
            required: false,
        },
        // ID key
        idKey: {
            type: String,
            required: false,
        },
        // 初始表单数据
        value: {
            type: Object,
            required: false,
        },
        // 表单校验
        validate: {
            type: Object,
            default: () => ({}),
        },
        // 数据前置事件
        dataBefore: {
            type: Function,
            required: false,
        },
        // 提交数据结构是否为 JSON，默认 true
        isJson: {
            type: Boolean,
            default: true,
        },
        dataWatch: {
            type: Object,
            required: false,
        },
        height: {
            type: String,
            required: false,
        },
        // 可否滚动
        isScroll: {
            type: Boolean,
            default: true,
        },
    },

    watch: {
        formData: {
            handler(v) {
                this.processWatchData(v, true)
            },
        },
    },

    created() {
        this.value && (this.formData = this.value)
    },

    mounted() {
        const menus = []

        Utils.each(this.$refs.form?.$children, v => {
            if (v.$vnode.tag.indexOf("ElemMenuItem") > -1) {
                v.display = false
                menus.push({
                    title: v.title,
                    id: Utils.getUuid(),
                    el: v,
                    condition: v.condition,
                })
            }
        })

        if (menus.length > 0) {
            this.currentMenu = menus[0].id
            // 显示第一个菜单内容
            menus[0].el.display = true
            this.menus = menus
        }
    },

    methods: {
        async display(id, data, extra) {
            await this.open(id, data, extra)
        },

        async open(id, data, extra) {
            this.extra = extra
            // 是否可编辑
            if (this.detailApi) {
                // 存在 ID 则获取详情
                if (id) await this.getDetail(id)
            }

            if (data && !id) {
                var d

                if (this.dataBefore) {
                    d = this.dataBefore(data)
                } else {
                    d = JSON.parse(JSON.stringify(data))
                }

                Utils.eachObj(d, (k, v) => {
                    this.$set(this.formData, k, v)
                })
            }

            this.detailId = id

            if (this.menus && this.menus.length > 0) {
                this.onChangeMenu(this.menus[0].id)
            }

            // 表单模式
            this.model = id ? "edit" : "create"

            // 显示窗口
            this.$refs.comp_model.display()
        },

        setValue(key, value) {
            if (typeof key === "object") {
                Utils.eachObj(key, (k, v) => {
                    this.$set(this.formData, k, v)
                })
            } else this.$set(this.formData, key, value)
        },

        /**
         * 提交表单事件
         */
        async onSubmit() {
            var data

            if (this.$refs.comp_form) {
                const res = await this.$refs.comp_form.getFormData()

                if (res.code !== 200) {
                    return this.$Message.error(res.msg)
                }

                data = res.data
            } else {
                data = { ...this.formData, ...this.watch_data }

                // 获取所有表单组件项
                const cs = this.getAllFormItem(data, this.$refs.form.$children)

                // 循环校验表单
                for (let i = 0, ks = Object.keys(cs); i < ks.length; i++) {
                    var k = ks[i]
                    var c = cs[k]

                    let name = c.value.name || k

                    if (!name === "undefined") {
                        continue
                    }

                    if ("string" === typeof name) {
                        name = [name]
                    }

                    for (let n_i = 0; n_i < name.length; n_i++) {
                        const n = name[n_i]

                        // 列表表单数据结构
                        if (c.type === "LIST") {
                            const list = c.value

                            // 循环数据源数组
                            for (let v_i = 0, d = data[n]; v_i < d?.length; v_i++) {
                                var d_v = d[v_i]

                                // 循环组件配置
                                for (let c_i = 0, c_ks = Object.keys(list); c_i < c_ks.length; c_i++) {
                                    var c_k = c_ks[c_i]
                                    var c_v = list[c_k].value
                                    var d_v_i = d_v[c_k]

                                    // 当前组件处于隐藏状态，移除数据
                                    if (!c_v.display) {
                                        delete d_v[c_k]
                                        continue
                                    }

                                    // 校验必选
                                    if (c_v.rq && Utils.isBlank(d_v_i)) {
                                        return this.$Message.error({
                                            content: `${c_v.title ?? c_v.name} 不能为空`,
                                            background: true,
                                        })
                                    }

                                    // 校验内容
                                    if (c_v.verify && d_v_i && !Utils.verify(c_v.verify, d_v_i)) {
                                        return this.$Message.error({
                                            content: `${c_v.title ?? c_v.name} 格式不正确`,
                                            background: true,
                                        })
                                    }
                                }
                            }
                        } else {
                            // 当前组件处于隐藏状态，移除数据
                            if (!c.value.display) {
                                delete data[n]
                                continue
                            }

                            // 校验必选
                            if (c.value.rq && Utils.isBlank(data[n])) {
                                return this.$Message.error({
                                    content: `${c.value.title ?? n} 不能为空`,
                                    background: true,
                                })
                            }

                            // 校验内容
                            if (c.value.verify && data[n] && !Utils.verify(c.value.verify, data[n])) {
                                return this.$Message.error({
                                    content: `${c.value.title ?? n} 格式不正确`,
                                    background: true,
                                })
                            }
                        }
                    }
                }
            }

            // 判断并触发父组件提交数据的前置事件
            if (this.submitBefore) {
                var cb = this.submitBefore(data, !!this.detailId, this.extra)

                if (typeof cb !== "undefined") {
                    // 判断是否为异步
                    if (cb instanceof Promise) {
                        cb = await cb
                    }

                    if (cb === false) return

                    // 使用前置事件返回的数据
                    data = cb
                }
            }

            // 存在 ID 时为修改模式
            if (this.detailId) {
                data[this.idKey] = this.detailId
            }

            if (!this.editApi && !this.submitApi) {
                // 提交事件
                this.$emit("on-submit", {
                    tag: "CompForm",
                    value: JSON.parse(JSON.stringify(data)),
                })
                // 关闭窗口
                this.$refs.comp_model.close()
                return
            }

            if (this.loading) return

            this.loading = this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            this.$post(
                (this.detailId ? this.editApi : 0) || this.submitApi,
                data,
                this.isJson
                    ? {
                          "Context-Type": "application/json",
                      }
                    : undefined
            )
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            content: this.detailId ? "更新成功" : "提交成功",
                            background: true,
                        })
                        // 关闭窗口
                        this.$refs.comp_model.close()
                        // 父页面刷新列表
                        this.$emit("on-submit", {
                            tag: "CompForm",
                            value: res.data,
                        })
                    } else {
                        this.$Message.error({
                            content: res.desc,
                            background: true,
                        })
                    }
                })
                .finally(() => {
                    this.loading()

                    this.loading = null
                })
        },

        getAllFormItem(formData, children = [], res = {}) {
            for (let i = 0; i < children.length; i++) {
                let n = children[i]

                let tag = n.$vnode?.tag

                if (tag.indexOf("ElemMenuItem") > -1) {
                    if (n.condition && !n.condition(formData)) continue
                } else if (tag.indexOf("ElemFormItem") > -1) {
                    if (!n.name) continue

                    res[n.name] = {
                        value: n,
                    }
                } else if (tag.indexOf("ElemFormList") > -1) {
                    if (!n.name) continue
                    // 获取所有 list 组件下的排列
                    res[n.name] = {
                        type: "LIST",
                        value: this.getAllFormItem(formData, n.$children),
                    }
                    // 不继续遍历
                    continue
                }

                if (n.$children?.length > 0) {
                    this.getAllFormItem(formData, n.$children, res)
                }
            }

            return res
        },

        /**
         * 监听窗口显示/隐藏事件
         */
        onCloseModal() {
            // 等待窗口关闭动画
            setTimeout(() => {
                this.$refs.comp_form?.setEmpty?.()
                // 清空表单
                Utils.eachObj(this.formData, k => {
                    // this.formData[k] = null
                    this.$set(this.formData, k, null)
                })
                // 重置表单字段 Form
                this.$refs.form?.resetFields?.()
                // 刷新
                this.$forceUpdate()
                // 触发事件
                this.$emit("on-close")
            }, 200)
        },

        /**
         * 获取详情数据
         */
        getDetail(id) {
            this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            return this.$get(this.detailApi, {
                [this.idKey]: id,
            })
                .then(res => {
                    if (res.code == 200) {
                        var data

                        if (this.dataBefore) {
                            data = this.dataBefore(res.data || res.dataList)
                        } else {
                            data = res.data
                        }

                        // Utils.eachObj(data, (k, v) => {
                        //     this.$set(this.formData, k, v)
                        // })

                        this.formData = data
                    } else
                        setTimeout(() => {
                            this.$Message.error({
                                content: "获取详情失败",
                                background: true,
                            })
                        })
                })
                .finally(this.$Message.destroy)
        },

        processWatchData(data, change) {
            if (!data || !this.dataWatch) return

            if (!this.watch_data) {
                this.watch_data = {}
            }

            const keys = Object.keys(this.dataWatch)

            for (let i = 0; i < keys.length; i++) {
                const k = keys[i]

                this.watch_data[k] = data[k]

                console.log(data)

                Object.defineProperty(data, k, {
                    set: v => {
                        this.watch_data[k] = v
                        this.dataWatch[k] && this.dataWatch[k](v, data)
                    },
                    get: () => {
                        return this.watch_data[k]
                    },
                })

                if (typeof this.dataWatch[k] === "function") {
                    this.dataWatch[k](data[k], data)
                }
            }
        },

        /**
         * 菜单改变事件
         */
        onChangeMenu(evt) {
            Utils.each(this.menus, v => {
                v.el.display = v.id === evt
            })

            this.currentMenu = evt

            this.$emit("on-change-menu", {
                tag: "CompForm",
                value: evt,
            })
        },
    },
}
</script>

<style lang="less" scoped>
.container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    /deep/.menu {
        height: 100%;
        .ivu-menu {
            height: 100%;
            width: 220px !important;
        }
    }

    .content-base {
        width: 100%;
        height: 100%;
        overflow: auto;
        flex: 1;

        .content-box {
            padding: 20px 30px 20px 20px;
        }
    }
}

/deep/ .validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}

/deep/ #modal_contentarea {
    overflow: inherit !important;
}
</style>
