<template>
    <div class="elem-textarea-box" :style="{ height: height }">
        <div ref="contenteditableDiv" class="textarea" v-if="contenteditable" contenteditable="true"></div>
        <textarea v-else class="textarea" :name="name" :title="title" :placeholder="placeholder" :required="required" v-model="val" :maxlength="max" :disabled="disabled"></textarea>
    </div>
</template>

<script>
export default {
    data() {
        return {
            original: null,
            check: null,
            val: null,
        }
    },

    props: {
        type: String,
        name: String,
        title: String,
        required: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: "请输入",
        },
        value: {
            type: String,
            default: "",
        },
        max: {
            type: Number,
            default: 1000,
        },
        // 是否禁用
        disabled: {
            type: Boolean,
            default: false,
        },
        // 内容编辑模式
        contenteditable: {
            type: Boolean,
            default: false,
        },
        height: {
            type: String,
            default: "150px",
        },
    },

    watch: {
        value: {
            handler(v) {
                if (v !== this.val) {
                    this.val = v

                    if (this.contenteditable && this.$refs.contenteditableDiv) {
                        this.$refs.contenteditableDiv.innerHTML = v
                    }
                }
            },
            immediate: true,
        },

        val(v) {
            this.$emit("on-change", {
                value: v,
                type: "elem-textarea",
                name: this.name,
                set: value => {
                    this.val = value
                },
            })
        },
    },

    mounted() {
        if (this.contenteditable) {
            var contenteditableDiv = this.$refs.contenteditableDiv
            contenteditableDiv.addEventListener("input", () => {
                this.val = contenteditableDiv.innerHTML
            })

            if (this.value) {
                contenteditableDiv.innerHTML = this.value
            }
        }
    },

    methods: {
        getFormValue() {
            return {
                name: this.name,
                title: this.title,
                value: this.val,
                required: this.required,
            }
        },
    },
}
</script>

<style lang="less">
@import (reference) "../style/utils.less";

.elem-textarea-box {
    .textarea {
        position: relative;
        padding: 12px;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        height: 100%;
        z-index: 10;
        outline: none;
        resize: none;
        font-size: 14px;
        border: 1px solid #e3e3e3;
        overflow: auto;

        .border-box;
        .radius(6px);
        .transition;

        &:hover,
        &:focus {
            border-color: #b3b3b3;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }

        textarea {
            resize: none;
            font-size: 14px;
        }
    }
}
</style>
