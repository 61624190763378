<template>
    <Button :loading="isLoading" @click="onClickEvt" v-bind="$props">
        <slot></slot>
    </Button>
</template>

<script>
export default {
    data() {
        return {
            isLoading: false,
        }
    },

    props: ["onClick", "type", "size", "shape", "long", "disabled", "icon", "custom-icon"],

    methods: {
        async onClickEvt(evt) {
            if (this.isLoading) {
                return
            }

            if (this.onClick) {
                const timeout = setTimeout(() => {
                    this.isLoading = true
                }, 10)

                const cb = this.onClick(evt)

                if (cb instanceof Promise) {
                    await cb
                }

                clearTimeout(timeout)
                this.isLoading = false
            }
        },
    },
}
</script>
