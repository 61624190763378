<template>
    <div>
        <div class="elem-addr-base">
            <div class="elem-addr-box">
                <input ref="input" autocomplete="new-password" :maxlength="max" type="text" class="input" :verify="verify" :title="title" :required="required" :name="name" :disabled="disabled || !manual" :placeholder="place_holder" v-model="val" />
                <div class="select-btn" @click="!disabled && onDisplaySelectAddr()" :style="{ cursor: disabled ? 'no-drop' : 'pointer' }">选择地址</div>
            </div>
        </div>

        <CompModal class="addr-model" ref="addr_model" title="选择地址" :onSuccess="onSelectAddr" :onClose="onCloseAddrModal">
            <div class="addr-tree-box">
                <Tree v-if="showTree" :code="code" @handleTree="onChangeTree"></Tree>
            </div>
        </CompModal>
    </div>
</template>

<script>
import CompModal from "@/views/residentdatabase/components/CompModal.vue"
import Tree from "@/views/residentdatabase/children/tree.vue"

export default {
    components: { CompModal, Tree },

    data() {
        return {
            val: null,
            place_holder: null,

            language: {
                enter: "输入或选择",
                optional: "可选",
            },

            showTree: false,
        }
    },

    props: {
        max: {
            type: Number,
            default: 255,
        },
        name: String,
        title: String,
        required: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: "",
        },
        value: {
            type: Object,
            default: () => ({}),
        },
        // 是否禁用
        disabled: {
            type: Boolean,
            default: false,
        },
        code: {
            type: String,
            default: "440103001101",
        },
        // 是否可手动输入
        manual: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        value: {
            handler(val) {
                this.val = val[this.name]
                this.houseCode = val.houseCode

                this.setValue()
            },
            immediate: true,
        },

        val(val) {
            if (val === this.value[this.name]) return
            // 写入表单值
            this.value[this.name] = val
        },
    },

    mounted() {
        this.place_holder = this.placeholder || `${this.language.enter}${this.title || this.name}${this.required ? "" : "(" + this.language.optional + ")"}`
    },

    methods: {
        setValue() {
            this.value["houseCode"] = this.houseCode
            this.value[this.name] = this.val
        },

        onDisplaySelectAddr() {
            if (!this.showTree) {
                this.showTree = true
            }

            this.$refs.addr_model.display()
        },

        // 点击树节点
        onChangeTree(val, treeData) {
            this.treeData = treeData
            if (val.length) {
                this.treeAddress = ""
                this.selectItem = val[0]
                this.getCurrentTitle(this.selectItem.id, treeData)
            } else {
                this.selectItem = {}
            }
        },

        getCurrentTitle(id, treeData) {
            for (let k in treeData) {
                if (treeData[k].id == id) {
                    this.treeAddress = (treeData[k].name || treeData[k].orgName) + this.treeAddress
                    if (!treeData[k].parentId) return
                    this.getCurrentTitle(treeData[k].parentId, this.treeData)
                } else if (treeData[k].children && treeData[k].children.length > 0) {
                    this.getCurrentTitle(id, treeData[k].children)
                }
            }
        },

        onSelectAddr() {
            if (!Object.keys(this.selectItem).length) {
                return this.$Message.warning({
                    content: "请选择现住地址",
                    background: true,
                })
            } else {
                if (this.selectItem.regionType != "0" && this.selectItem.regionType != "4") {
                    return this.$Message.warning({
                        content: "现住地址请选择到房号",
                        background: true,
                    })
                } else {
                    this.val = this.treeAddress
                    this.houseCode = this.selectItem.code

                    this.setValue()

                    // 关闭窗口
                    this.$refs.addr_model.close()
                }
            }
        },

        onCloseAddrModal() {
            this.$refs.addr_model.close()
        },
    },
}
</script>

<style lang="less">
@import (reference) "../style/utils.less";

.elem-addr-base {
    .elem-addr-box {
        position: relative;
        width: 100%;
        height: 40px;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        .input {
            position: relative;
            height: 40px;
            padding: 0 104px 0 12px;
            font-size: 14px;
            z-index: 10;
            box-sizing: border-box;
            border-radius: 6px;
            transition: all 0.3s ease;
            background: #fff;
            border: 1px solid #e3e3e3;
            width: 100%;

            &:hover,
            &:focus {
                border-color: #b3b3b3;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }
        }

        .select-btn {
            position: absolute;
            top: 7px;
            right: 7px;
            bottom: 7px;
            width: 90px;
            background: #2faaf7;
            color: #fff;
            font-size: 13px;
            z-index: 20;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
        }
    }
}

.addr-model {
    .addr-tree-box {
        padding: 10px;
    }
}
</style>
