<template>
    <div class="comp-content">
        <!--工具栏-->
        <div class="toolbar" id="toolbar" ref="toolbar">
            <!--标题-->
            <div class="title-box">
                <Icon type="md-arrow-round-back" v-if="Route.isBack()" @click="onBack" size="24" />
                <Icon type="md-home" v-else @click="onBack" size="24" />
                <div style="padding: 0 6px; font-size: 16px; white-space: nowrap; cursor: pointer" ref="title" @click="onBack">
                    {{ $core.getUrlParam("menuName") || title }}
                </div>
            </div>
            <div class="significant-box">
                <slot name="significant"></slot>
            </div>
            <div class="tool-box">
                <div class="button-box">
                    <!-- 操作按钮模板 -->
                    <slot name="operate"></slot>
                </div>
                <slot name="custom"></slot>
            </div>
        </div>
        <div class="content-box" ref="content">
            <div class="search-box" v-show="isSearch">
                <!-- 搜索组件表单 -->
                <Form ref="search_form" :model="search" :label-colon="true" :inline="true" class="search">
                    <slot name="search" :search="search"></slot>
                </Form>
                <!-- 搜索操作按钮：存在搜索条件时 -->
                <Button class="btn" v-if="isSearch" type="primary" icon="ios-search" @click="onSearch">查询</Button>
                <Button class="btn" v-if="isSearch" type="success" icon="ios-refresh" @click="onReset">重置</Button>
            </div>
            <Alert class="error-box" type="error" show-icon closable v-if="error">{{ error }}</Alert>
            <div class="content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import Route from "../../communitymobilization/entity/Route"

export default {
    data() {
        return {
            Route: Route,
            isSearch: false,
        }
    },

    props: {
        title: String,

        intercept: {
            type: Object,
            default: () => ({}),
        },

        search: {
            type: Object,
            default: () => ({}),
        },
        // 错误信息
        error: {
            type: String,
            required: false,
        },
    },

    mounted() {
        // 是否存在表单组件，存在即开启搜索功能
        this.isSearch = this.$refs.search_form.$children.length > 0
        // 观察组件
        this.observer = window.MutationObserver
            ? new window.MutationObserver(mutationsList => {
                  for (let mutation of mutationsList) {
                      if (mutation.type === "childList") {
                          this.isSearch = mutation.target.childElementCount > 0
                      }
                  }
              })
            : null
        // 开始观察
        this.observer?.observe(this.$refs.search_form.$el, {
            childList: true,
        })
    },

    beforeDestroy() {
        // 停止观察
        this.observer?.disconnect()
    },

    methods: {
        onBack() {
            // 拦截事件
            if (this.intercept?.back && this.intercept?.back() === false) {
                return
            }

            Route.back()
        },

        onSearch() {
            this.$emit("on-search", {
                tag: "CompContent",
                value: this.search,
            })
        },

        onReset() {
            // 清空搜索数据
            Object.keys(this.search).forEach(v => {
                this.$set(this.search, v, null)
            })
            // 触发事件
            this.$emit("on-reset", {
                tag: "CompContent",
            })
        },

        getContentEl() {
            return this.$refs.content
        },
    },
}
</script>

<style lang="less" scope>
.comp-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .toolbar {
        position: sticky;
        top: 0;
        background: #fafafa;
        width: 100%;
        min-height: 56px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid #e9e9e9;
        z-index: 2;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;

        .title-box {
            cursor: pointer;
            display: flex;
            align-items: center;
        }

        .tool-box {
            display: flex;
            align-items: center;
            padding: 5px 0 5px 20px;
        }
    }

    .toolsbarRight {
        align-items: center;

        div,
        button {
            margin: 5px 0 5px 8px !important;
        }
    }

    > .content-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
        flex-grow: 1;

        > .table-box {
            flex-grow: 1;
            padding: 0 10px 10px;
            overflow: hidden;
        }

        > .content {
            flex-grow: 1;
            padding: 0 10px;
            overflow: auto;
            display: flex;
            flex-direction: column;
        }

        > .search-box {
            position: relative;
            padding: 8px 10px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex-shrink: 0;
            z-index: 30;

            .search {
                > div {
                    display: inline-block;
                    margin: 5px 8px 5px 0 !important;
                    width: auto;
                    min-width: 200px;
                    max-width: 500px;
                }
            }

            .btn {
                margin: 5px 10px 5px 0;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        > .error-box {
            margin: 10px;
        }
    }

    .search {
        padding-right: 10px;

        .ivu-form-item {
            margin: 5px 10px 5px 0 !important;
        }
    }

    .button-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        > div,
        > button {
            margin: 3px 0 3px 5px;
        }
    }
}
</style>
