<template>
    <block>
        <Input v-if="search" v-model="search_value" class="input" placeholder="输入搜索内容" search :disabled="disabled" enter-button="搜索" @on-search="onSearch"></Input>
        <div class="group-box">
            <div class="group" :class="{ column: column }">
                <div class="checkbox" v-for="item in items" :class="{ radio: !multiple }" :style="{ width: item.form ? '100%' : '' }" :key="items.value">
                    <div class="info">
                        <Checkbox :class="{ radio: !multiple }" :disabled="disabled" :value="selecteds.includes(item.value)" @on-change="onChangeCheckbox(item, $event)">{{ item.name || item.value }}</Checkbox>
                        <input v-if="isDisplayInput && item.optionsAdditional" class="other-input" :value="other_input" :placeholder="item.optionsAdditional.fieldName || '其他'" @input="onInput" />
                    </div>
                    <div class="form-btn" v-if="selecteds.includes(item.value) && item.form" @click.stop="onDisplaySmallForm(item)">
                        {{ item.form.title || "编辑" }}
                    </div>
                </div>
            </div>
        </div>
        <p class="empty" v-if="!items || items.length === 0">
            <Icon class="icon" type="ios-alert-outline" />
            {{ search_value ? "暂无搜索结果" : "暂无数据" }}
        </p>

        <CompModal ref="comp_modal" :title="small_title" height="auto">
            <CompForm :forms="small_forms" :value="small_formdata" @on-submit="onSubmitSmallForm"></CompForm>
        </CompModal>
    </block>
</template>

<script>
import CompModal from "../../residentdatabase/components/CompModal.vue"
import CompForm from "./CompForm.vue"

export default {
    components: { CompModal, CompForm },

    data() {
        return {
            selecteds: [],
            search_value: "",
            items: [],
            val: null,
            // 显示输入框
            isDisplayInput: false,
            // 其他输入框
            other_input: "",
            // 表单
            small_forms: null,
            // 表单标题
            small_title: null,
            // 表单值
            small_formdata: null,
        }
    },
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        // 值
        value: {
            type: String || Array,
            required: false,
        },
        // 节点数据
        list: Object,
        // 多选
        multiple: {
            type: Boolean,
            default: false,
        },
        // 列布局
        column: {
            type: Boolean,
            default: false,
        },
        // 搜索
        search: {
            type: Boolean,
            default: false,
        },
        // 禁用
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        list: {
            handler(val) {
                if (val === this.items) return
                this.items = val
            },
            immediate: true,
        },
        // items(v, o) {
        //     if (o) this.selecteds = []
        // },
        selecteds(v) {
            this.onChangeSelecteds()
        },
        value: {
            handler(val, o) {
                if (val === this.val || val === o) return
                if (val === null || val === undefined || val === "") {
                    // 初始化数据
                    this.selecteds = []
                    this.search_value = ""
                    this.isDisplayInput = false
                    this.other_input = ""
                    return
                }
                if (this.multiple && typeof val === "string") {
                    val = val.split(",")
                }
                if (this.list) {
                    // 多选
                    if (this.multiple) {
                        // 查询非选项值，列入其他输入框
                        this.other_input = val.findLast(v => this.list.findIndex(l => l.value === v) === -1) || ""
                        if (this.other_input) {
                            this.isDisplayInput = true
                            // 查找其他项
                            let other = this.list.findLast(v => v.optionsAdditional)?.value
                            other && val.push(other)
                        }
                    } else {
                        let idx = this.list.findIndex(l => l.value === val)
                        // 查找非选项值
                        if (idx === -1) {
                            let other = this.list.findLast(v => v.optionsAdditional)?.value
                            if (other) {
                                this.other_input = val
                                this.isDisplayInput = true
                                // 单选值嵌入数组中
                                val = [other]
                            }
                        } else if (this.list[idx].optionsAdditional) {
                            this.isDisplayInput = true
                        }
                    }
                }
                this.selecteds = val ? (val instanceof Array ? val : [val]) : []
            },
            immediate: true,
        },
        search_value(v, o) {
            if (!v && o) {
                this.items = this.list
            }
        },
        other_input(v) {
            this.onChangeSelecteds()
        },
    },
    methods: {
        onChangeCheckbox(item, event) {
            var s = this.selecteds
            if (this.multiple) {
                let idx = s.indexOf(item.value)
                if (idx > -1) {
                    s.splice(idx, 1)
                } else {
                    s.push(item.value)
                }
                if (item.optionsAdditional) {
                    this.isDisplayInput = event
                }
            } else {
                this.selecteds = event ? [item.value] : []
                // 当前事件为选中 + 选项为 optionsAdditional 类型，显示输入框
                this.isDisplayInput = event && item.optionsAdditional
            }
        },
        onChangeSelecteds() {
            var v = this.selecteds,
                val
            if (this.multiple) {
                val = this.list
                    .filter(l => v.includes(l.value) && !l.optionsAdditional)
                    .map(m => {
                        if (m.form) {
                            return {
                                value: m.value,
                                formdata: m.formdata,
                            }
                        } else {
                            return m.value
                        }
                    })
                // 是否存在其他输入选项
                if (this.isDisplayInput && this.other_input) {
                    val.push(this.other_input)
                }
            } else {
                val = v?.[0]
                // 是否存在其他输入选项
                if (this.isDisplayInput && this.other_input) {
                    val = this.other_input
                }
            }
            this.val = val
            this.$emit("change", val)
            this.$emit("on-change", {
                tag: "ElemCheck",
                value: this.list?.filter(c => v.includes(c.value)) || [],
            })
        },
        onSearch(value) {
            this.items = this.list?.filter(v => v?.name?.indexOf(value) > -1)
        },
        onInput(evt) {
            this.other_input = evt.target.value
        },

        /**
         * 打开表单
         */
        onDisplaySmallForm(item) {
            this.small_forms = item.form.forms
            this.small_title = item.name || item.value
            this.small_formdata = item.formdata
            this.small_item = item
            // 打开表单弹窗
            this.$refs.comp_modal.display()
        },

        // 表单提交事件
        onSubmitSmallForm(evt) {
            this.small_item.formdata = evt.value
            // 关闭弹窗
            this.$refs.comp_modal.close()
        },
    },
}
</script>

<style lang="less" scoped>
.input {
    width: 100%;
    margin-bottom: 10px;
}

.group-box {
    display: flex;
    flex-wrap: wrap;
}

.group {
    display: flex;
    flex-wrap: wrap;

    .checkbox {
        margin-right: 5px;
        display: flex;
        justify-content: space-between;

        .info {
            display: flex;
            align-items: center;
            flex-grow: 1;
            padding: 5px 0;
        }

        .form-btn {
            cursor: pointer;
            flex-shrink: 0;
            margin-left: 5px;
            padding: 0 10px;
            height: 25px;
            font-size: 13px;
            background: #e3e3e3;
            color: #333;
            display: flex;
            align-items: center;
            border-radius: 5px;
        }
    }

    /deep/.ivu-checkbox-wrapper {
        margin-right: 15px;
        line-height: 1;

        &:last-child {
            margin-right: 8px;
        }
    }

    &.column {
        display: flex;
        flex-direction: column;

        /deep/.ivu-checkbox-wrapper {
            margin-right: 0;
            margin-bottom: 5px;
        }
    }

    .radio {
        /deep/.ivu-checkbox-inner {
            width: 18px;
            height: 18px;
            border-radius: 50%;

            &::after {
                top: 3px;
                left: 6px;
                height: 9px;
            }
        }
    }
}

.other-input {
    border: initial;
    border-bottom: 1px solid #e3e3e3;
    width: 150px;
    padding: 0 5px;
    text-align: center;
}

.empty {
    margin: 0 auto;
    text-align: center;
    margin-top: 20px;
    color: #888;
    font-size: 12px;

    .icon {
        margin-right: 3px;
        font-size: 16px;
    }
}
</style>
