import html2canvas from "html2canvas"
import core from "@/utils/core"

import { Message } from "view-design"
import Loading from "../../jointly/utils/loading"

export default class FileUtil {
    /**
     * 下载文件
     * @param {String} url 文件地址
     * @param {String} title 文件名
     **/
    static download(url, title = "file") {
        var a = document.createElement("a")
        a.download = title
        a.href = url
        a.click()
    }

    /**
     * 导出为 PDF
     */
    static async exportToPDF(element) {
        // 加载提示
        Loading.show()
        // 处理HTML图片
        await this.__processImage(element)
        // 生成图片
        const canvas = await html2canvas(element, { useCORS: true })
        var iframe = document.createElement("iframe")
        iframe.setAttribute("class", "print-iframe")
        iframe.setAttribute("style", "position:absolute;left:0;top:0;visibility:hidden;")
        document.body.appendChild(iframe)
        var doc = iframe.contentWindow.document

        // 样式
        const style = doc.createElement("style")
        style.innerHTML = `
            @media print {
                body {
                    margin: 0;
                    -webkit-print-color-adjust: exact;
                }

                .no-print {
                    display: none;
                }
            }

            @page {
                margin-bottom: 0mm;
                margin-top: 0mm;
            }
        `
        doc.body.appendChild(style)

        // 固定宽度 800 px，计算比例
        const ratio = 800 / canvas.width
        const img = doc.createElement("img")
        img.src = canvas.toDataURL("image/png")
        img.style.height = `${canvas.height * ratio}px`
        img.style.width = "800px"
        // 监听图片加载完成事件
        img.onload = () => {
            // 触发打印
            iframe.contentWindow.print()
            if (navigator.userAgent.indexOf("MSIE") > 0) {
                document.body.removeChild(iframe)
            }
            // 完成
            this.__complete()
        }
        doc.body.appendChild(img)
        doc.close()
        iframe.contentWindow.focus()
    }

    /**
     * 导出为图片
     */
    static async exportToPhoto(element, title = "数据导出") {
        // 加载提示
        Loading.show()
        // 处理HTML图片
        await this.__processImage(element)
        // 生成图片
        html2canvas(element, { useCORS: true })
            .then(canvas => {
                let src = canvas.toDataURL()
                var image = new Image()

                // 解决跨域 Canvas 污染问题
                image.setAttribute("crossOrigin", "anonymous")
                image.onload = () => {
                    canvas.width = image.width
                    canvas.height = image.height
                    var context = canvas.getContext("2d")
                    context.drawImage(image, 0, 0, image.width, image.height)
                    var url = canvas.toDataURL("image/png", 1.0)
                    var a = document.createElement("a")
                    var event = new MouseEvent("click")
                    a.download = title
                    a.href = url
                    a.dispatchEvent(event)
                    // 完成
                    this.__complete()
                }
                image.onerror = () => {
                    this.__complete(true)
                }
                image.src = src
            })
            .catch(() => {
                this.__complete(true)
            })
    }

    static async __processImage(elem) {
        const imgs = elem.getElementsByTagName("img")

        for (let i = 0; i < imgs.length; i++) {
            var item = imgs[i]
            item.src = await core.urlToBase64(item.src).catch(() => {
                this.__complete(true)
            })
        }
    }

    static __complete(e) {
        // 销毁加载提示
        Loading.hide()
        // 错误提示
        e && Message.error("导出失败")
    }
}
