<template>
    <div class="elem-table-page">
        <div class="table-box">
            <div class="base-box">
                <div class="content-box">
                    <div class="head">
                        <div class="item" style="width:230px" v-for="(item, idx) in keys" :key="idx">{{ item.fieldName }}</div>
                    </div>
                    <div class="body">
                        <div class="row" v-for="(item, idx) in importData" :key="idx">
                            <div class="column" style="width:230px" v-for="(cd, idx) in item.columnDataList" :key="'column' + idx">
                                <div class="column-box">
                                    <p class="text-box">{{ cd }}</p>
                                </div>
                            </div>
                        </div>
                        <p class="empty" v-if="!importData || 0 >= importData.length">当前数据为空</p>
                    </div>
                </div>
                <div class="operating-box" :class="{ 'operating-suspension': suspension }">
                    <div class="operating-title">操作</div>

                    <div class="operating-item" v-for="(item, idx) in table" :key="idx">
                        <div class="button-box" v-for="(conf, idx) in operatings" :key="idx">
                            <span v-if="conf.type === 'EDIT' ? tableConfig.pages.indexOf('create') > -1 : true">
                                <!-- 修改 -->
                                <a v-if="conf.type === 'EDIT'" class="edit" @click="jumpForm({ type: 'edit', name: name, i: item.uuid })">编辑</a>

                                <!-- 删除 -->
                                <button v-else-if="conf.type === 'DELETE'" class="delete" @click="onDelete(item.uuid)">删除</button>

                                <button v-else-if="conf.type === 'POPUP'" :style="{ background: conf.background, color: conf.color }" @click="onOperatingPopup(item, conf.url, conf.msg, conf.config)">
                                    {{ conf.title }}
                                </button>

                                <!-- 上传文件 -->
                                <button v-else-if="conf.type === 'FILE'" :style="{ background: conf.background, color: conf.color }" @click="onOperatingFile(item, conf.url, conf.msg, conf.config)">
                                    {{ conf.title }}
                                </button>

                                <!-- 跳转页面 -->
                                <button v-else-if="conf.type === 'JUMP'" :style="{ background: conf.background, color: conf.color }" @click="onOperatingJump(item, conf.url, conf.msg)">
                                    {{ conf.title }}
                                </button>

                                <!-- 发起请求 -->
                                <button
                                    v-else-if="conf.type === 'REQUEST'"
                                    :style="{ background: conf.background, color: conf.color }"
                                    @click="onOperatingRequest(conf.method, item, conf.url, conf.msg)"
                                >
                                    {{ conf.title }}
                                </button>

                                <!-- 显示实体 -->
                                <button v-else-if="conf.type === 'ENTITY'" class="entity" @click="openEntity(item)">{{ conf.title }}</button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            suspension: false,
        }
    },

    props: {
        operatings: Array,
    },
}
</script>

<style></style>
