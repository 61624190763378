<template>
    <div class="form-item" :class="{ column }" v-show="display" :is-hide="!display">
        <div class="name-box" :style="{ width: column ? '100%' : width }">
            <p class="required" v-if="rq">*</p>
            {{ title }}:
        </div>
        <div class="form-content">
            <slot :cfg="{ ph, model, style }"></slot>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            ph: "请填写",
            rq: false,
            style: "width:300px",
        }
    },

    props: {
        title: String,
        required: {
            type: Boolean,
            default: false,
        },
        name: String || Array,
        verify: {
            type: String,
            required: false,
        },
        display: {
            type: Boolean,
            default: true,
        },
        width: {
            type: String,
            default: "100px",
        },
        column: {
            type: Boolean,
            default: false,
        },
    },

    created() {
        this.ph += this.title

        if (this.required === null && this.name) {
            this.rq = true
        } else {
            this.rq = this.required
        }
    },
}
</script>
<style lang="less" scoped>
.form-item {
    vertical-align: top;
    zoom: 1;
    display: flex;
    margin: 10px 0;

    .name-box {
        width: 100px;
        height: 40px;
        font-size: 14px;
        margin-right: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-shrink: 0;

        .required {
            margin-right: 5px;
            font-size: 20px;
            color: #ff0000;
            line-height: 1;
        }
    }

    .form-content {
        min-height: 40px;
        display: flex;
        align-items: center;
        flex: 1;
    }

    &.column {
        flex-direction: column;
        align-items: flex-start;

        .name-box {
            width: 100%;
            height: auto;
            justify-content: flex-start;
            margin-bottom: 2px;
        }
    }
}
</style>
