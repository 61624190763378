<template>
    <div class="comp-form-page" :class="{ full: full }">
        <div class="form-box" ref="form_box" :style="{ 'padding-bottom': isDisplaySubmit ? '80px' : '20px' }">
            <div class="item-box" v-for="(item, idx) in forms" :class="{ 'item-title': item.type === 'text' }" :key="'form' + idx" v-show="!item.condition || processCondition(item.condition)">
                <div class="module-title" v-if="item.type === 'text'">
                    <p class="serial-number">{{ getModuleSerial(item) }}</p>
                    <p class="name">{{ item.title }}</p>
                </div>
                <div class="title" v-else>
                    {{ (item.serial ? item.serial : getSerial(item, idx) + ". ") + item.title + getTypeName(item.type) }}
                    <span v-if="item.required" style="color:red">*</span>
                </div>
                <component v-if="item.component" v-bind:is="item.component" :config="item" :name="item.name" :value="getFormValue(item)" :formdata="formdata" @on-change="onChangeValue($event.name, $event.value, item)"></component>
                <component
                    v-else-if="item.type === 'input'"
                    v-bind:is="getComponent('ElemInput')"
                    :value="getFormValue(item)"
                    :name="item.name"
                    :title="item.title"
                    :max="item.max"
                    :verify="item.verify"
                    :disabled="disabled"
                    :unit="item.unit"
                    @on-input="onChangeValue($event.name, $event.value, item)"
                ></component>
                <!-- 数字类型 input -->
                <component
                    v-else-if="item.type === 'inputNumber' || item.type === 'number'"
                    v-bind:is="getComponent('ElemInput')"
                    :value="getFormValue(item)"
                    :name="item.name"
                    type="number"
                    :title="item.title"
                    :max="item.max"
                    :verify="item.verify"
                    :disabled="disabled"
                    :unit="item.unit"
                    @on-input="onChangeValue($event.name, $event.value, item)"
                ></component>
                <!-- 文本 -->
                <component
                    v-else-if="item.type === 'textarea'"
                    v-bind:is="getComponent('ElemTextarea')"
                    :value="getFormValue(item)"
                    :name="item.name"
                    :title="item.title"
                    :max="item.max"
                    :verify="item.verify"
                    :disabled="disabled"
                    :height="item.height"
                    :contenteditable="item.contenteditable"
                    @on-change="onChangeValue($event.name, $event.value, item)"
                ></component>
                <!-- 单选 -->
                <component v-else-if="item.type === 'radio'" v-bind:is="getComponent('ElemCheck')" :value="getFormValue(item)" :list="item.options" :disabled="disabled" @change="onChangeValue(item.name, $event, item, 'checkbox')"></component>
                <!-- 多选 -->
                <component v-else-if="item.type === 'checkbox'" v-bind:is="getComponent('ElemCheck')" :value="getFormValue(item)" :list="item.options" :disabled="disabled" :multiple="true" @change="onChangeValue(item.name, $event, item, 'checkbox')"></component>
                <!-- 开关 -->
                <component
                    v-else-if="item.type === 'switch'"
                    v-bind:is="getComponent('ElemSwitch')"
                    :value="getFormValue(item)"
                    :name="item.name"
                    :title="item.title"
                    :disabled="disabled"
                    key-on="是"
                    key-off="否"
                    :value-on="config && config.switch && config.switch.open ? config.switch.open : true"
                    :value-off="config && config.switch && config.switch.close ? config.switch.close : false"
                    shape="square"
                    @on-change="onChangeValue($event.name, $event.value, item)"
                ></component>
                <!-- 下拉选择 -->
                <component v-if="item.type === 'select'" v-bind:is="getComponent('ElemSelect')" :options="item.options" :value="getFormValue(item)" :name="item.name" :title="item.title" :disabled="disabled" @on-change="onChangeValue($event.name, $event.value, item)"></component>
                <!-- 上传文件 -->
                <component
                    v-else-if="item.type === 'upload'"
                    v-bind:is="getComponent('ElemUpload')"
                    :name="item.name"
                    :length="item.max || 1"
                    :required="item.required"
                    :type="item.resource_type"
                    :value="getFormValue(item)"
                    :title="item.title"
                    :disabled="disabled"
                    @on-load="onLoadResource"
                ></component>
                <!-- 选择日期 -->
                <component v-else-if="item.type === 'datePicker'" :type="item.dateType" v-bind:is="getComponent('ElemDate')" :name="item.name" :value="getFormValue(item)" :disabled="disabled" @on-change="onChangeValue(item.name, $event.value, item)"></component>
                <!-- 选择时间 -->
                <div class="date" v-else-if="item.type == 'timePicker'">
                    <DatePicker :value="getFormValue(item)" type="time" placeholder="选择时间" :disabled="disabled" @on-change="onChangeValue(item.name, $event, item)" />
                </div>
                <!-- 选择地址 -->
                <component v-else-if="item.type === 'addr'" v-bind:is="getComponent('ElemAddr')" :value="formdata" :name="item.name" :title="item.title" :max="item.max" :disabled="disabled" :code="param.code"></component>
                <!-- 选择地址架构 -->
                <component v-else-if="item.type === 'selectOrg'" v-bind:is="getComponent('ElemOrg')" :value="formdata" :name="item.name" :title="item.title" :max="item.max" :disabled="disabled" :code="param.code"></component>
                <!-- 民族 -->
                <component v-else-if="item.type === 'national'" v-bind:is="getComponent('ElemSelect')" mode="nation" :value="getFormValue(item)" :name="item.name" :title="item.title" :disabled="disabled" @on-change="onChangeValue($event.name, $event.value, item)"></component>
                <!-- 标签 -->
                <component v-else-if="item.type === 'label'" v-bind:is="getComponent('ElemLabel')" :value="getFormValue(item)" :name="item.name" :title="item.title" :disabled="disabled" @on-change="onChangeValue($event.name, $event.value, item)"></component>
                <!-- 联系号码 -->
                <component
                    v-else-if="item.type === 'mobile'"
                    :multiple="item.multiple"
                    v-bind:is="getComponent('ElemMobile')"
                    :value="getFormValue(item)"
                    :name="item.name"
                    :title="item.title"
                    :max="item.max"
                    :disabled="disabled"
                    @on-input="onChangeValue($event.name, $event.value, item)"
                ></component>
                <!-- 评分 -->
                <Rate v-else-if="item.type === 'rate'" :value="getFormValue(item)" :disabled="disabled" @on-change="onChangeValue(item.name, $event, item)" />
                <block class="child-box" v-if="item.childFormOperates">
                    <span>：</span>
                    <div class="item" v-for="(c, i) in item.childFormOperates" :key="i">
                        <span>{{ c.fieldName }}</span>
                        <input v-model="formdata[c.fieldId]" class="input" type="text" />
                        <span v-if="c.describe">{{ c.describe }}</span>
                        <span v-if="i < item.childFormOperates.length - 1">，</span>
                    </div>
                </block>
            </div>
            <!-- 占位标签 -->
            <div class="item-box"></div>
            <div class="item-box"></div>
        </div>
        <div v-if="isDisplaySubmit" class="submit-btn-box">
            <button class="submit-btn" @click="onSubmit()">{{ submitButton }}</button>
        </div>
    </div>
</template>

<script>
import Utils from "../utils/utils"

export default {
    data() {
        return {
            key: Utils.getUuid(),
            isDisplayPage: false,
            formdata: {},
        }
    },

    props: {
        forms: Array,
        // 图标
        icon: {
            type: String,
            required: false,
        },
        // 标题
        title: {
            type: String,
            default: "数据",
        },
        // 提交按钮名称
        submitButton: {
            type: String,
            default: "提交",
        },
        // 值
        value: {
            type: Object,
            required: false,
        },
        // 是否显示提交按钮
        isDisplaySubmit: {
            type: Boolean,
            default: true,
        },
        // 是否禁用
        disabled: {
            type: Boolean,
            default: false,
        },
        // 监听表单数据变化
        dataWatch: {
            type: Object,
            required: false,
        },
        // 额外参数
        param: {
            type: Object,
            default: () => ({}),
        },
        // 配置参数
        config: {
            type: Object,
            default: new Object(),
        },
        // 全屏
        full: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        value: {
            handler(v) {
                if (v) {
                    if (v === this.formdata) return

                    // checkbox 必须有默认数组值
                    Utils.each(this.forms, val => {
                        if (val.fieldType === "checkbox" && !v[val.fieldId]) {
                            v[val.fieldId] = []
                        }
                    })

                    Utils.eachObj(v, (k, val) => {
                        v[k] = this.parse(k, val)
                    })

                    this.formdata = v
                    // 刷新数据
                    this.$forceUpdate()

                    for (let i = 0, ks = Object.keys(v); i < ks.length; i++) {
                        let k = ks[i]
                        // 调用监听函数
                        this.dataWatch?.[k]?.(v[k])
                    }
                } else {
                    this.setEmpty()
                }
                // 滚动到顶部
                this.scrollTop()
            },
            immediate: true,
        },
    },

    methods: {
        parse(k, val) {
            if (typeof val === "string") {
                if (/^[\\{\\[].*[\]\\}]$/.test(val)) {
                    val = JSON.parse(val)
                } else if (~val.indexOf("|")) {
                    val = val.split("|")
                }
            }

            typeof val === "string" &&
                Utils.each(
                    this.forms,
                    v => {
                        if (v.fieldType === "checkbox") {
                            val = [val]
                            return "break"
                        }
                    },
                    c => c.fieldId === k
                )

            return val
        },

        /**
         * 滚动到顶部
         */
        scrollTop() {
            this.$nextTick(() => {
                this.$refs.form_box.scrollTop = 0
            })
        },

        /**
         * 监听资源加载事件
         */
        onLoadResource(evt) {
            if (!this.resources) {
                this.resources = []
            }

            this.resources.push({
                name: evt.name,
                entity: evt.value,
            })
        },

        /**
         * 数据变化事件
         * @param {object} evt 事件
         */
        onChangeValue(name, value, item, tag) {
            value = this.processValue(value, tag)
            // 写入值
            this.setValue(name, value)
            // 回调配置的改变事件
            item?.onChange?.(value)
            // 调用监听函数
            this.dataWatch?.[name]?.(value)
            // 组件改变事件
            this.$emit("on-change", {
                tag: "CompForm",
                value: value,
                name: name,
                data: this.formdata,
            })
        },

        /**
         * 处理值
         */
        processValue(value, tag) {
            switch (tag) {
                case "checkbox":
                    if (typeof value === "boolean") {
                        return ""
                    }
                    break
            }

            return value
        },

        /**
         * 处理条件参数
         */
        processCondition(c) {
            // 条件参数为函数时，调用并使用返回结果
            if (typeof c === "function") {
                return c(this.formdata)
            }
            // 字符串条件
            return new Function(`return ${c.replace(/(\${.*)(\.)(.*})/g, "$1?.$3").replace(/\${(.*)}/g, "this.$1")}`).call(this.formdata)
        },

        /**
         * 写入表单值
         * @param {string} name 名称
         * @param {any} value 值
         */
        setValue(name, value) {
            this.formdata[name] = value
        },

        /**
         * 提交事件
         */
        async onSubmit(source = "submit") {
            const res = await this.getFormData()

            if (res.code !== 200) {
                return this.$Message.error(res.msg)
            }

            this.$emit("on-submit", {
                tag: "CompForm",
                value: res.data,
                source,
            })
        },

        /**
         * 获取表单数据
         */
        async getFormData() {
            const f = this.forms
            const d = this.formdata
            const r = this.resources

            if (r && r.length > 0) {
                for (let i = 0; i < r.length; i++) {
                    let v = r[i]
                    let fs = await v.entity.getFiles()

                    d[v.name] = fs
                }
            }

            for (let i = 0; i < f.length; i++) {
                const e = f[i]

                // 标题类型不需要处理
                if (e.type === "text") {
                    continue
                }

                // 判断是否存在条件，而且处于隐藏状态，不校验必选项
                if (e.condition && !this.processCondition(e.condition)) {
                    delete d[e.name]
                    continue
                }

                // 如果为开关按钮，设置默认值
                if (e.type === "switch" && typeof d[e.name] === "undefined") d[e.name] = this.config?.switch?.close || false

                if (e.type === "radio" && Utils.isExist(d[e.name])) {
                    let s = e.options.find(v => v.value === d[e.name])

                    if (s?.optionsAdditional?.required) {
                        return {
                            code: 400,
                            msg: "请填写" + e.title + " - " + (s.optionsAdditional.fieldName || "其他"),
                        }
                    }
                }

                // 校验必选项
                if (e.required && Utils.isBlank(d[e.name])) {
                    return {
                        code: 400,
                        msg: "请填写" + (e.title || "必选项"),
                    }
                }

                // 校验数据格式
                if (e.verify && !Utils.isBlank(d[e.name]) && !Utils.verify(e.verify, d[e.name])) {
                    return {
                        code: 400,
                        msg: e.title + "格式不正确",
                    }
                }
            }

            return {
                code: 200,
                data: d,
            }
        },

        /** 获取组件 */
        getComponent(name) {
            return require(`./${name}.vue`).default
        },

        /**
         * 置空
         */
        setEmpty() {
            // checkbox 必须有默认数组值
            Utils.each(this.forms, v => {
                if (v.fieldType === "checkbox") {
                    this.$set(this.formdata, v.fieldId, [])
                }
            })
            // 置空对象值
            Utils.eachObj(this.formdata, k => {
                this.$set(this.formdata, k, null)
            })
            // 拷贝
            this.formdata = JSON.parse(JSON.stringify(this.formdata))
        },

        /**
         * 获取模块序号
         * @param {*} item
         */
        getModuleSerial(item) {
            // 将普通序号初始化
            this.serial = 0

            // 已经初始化了序号
            if (item.moduleSerial) {
                return item.moduleSerial
            }

            // 自增或初始值1
            return (item.moduleSerial = this.moduleSerial = this.moduleSerial ? ++this.moduleSerial : 1)
        },

        getSerial(item, idx) {
            if (idx === 0) {
                this.serial = 0
            }

            return ++this.serial
        },

        /**
         * 获取类型名称
         */
        getTypeName(type) {
            let n = { radio: "单选", checkbox: "多选" }[type]
            // 存在则加括号
            return n ? `（${n}）` : ""
        },

        getFormValue(item) {
            const v = this.formdata[item.name]

            if (v || v === false || v === 0) {
                return v
            }

            if (item.value || item.value === false || item.value === 0) {
                this.formdata[item.name] = item.value
            }

            return this.formdata[item.name]
        },
    },
}
</script>

<style lang="less">
@import "../style/utils.less";

.comp-form-page {
    position: relative;
    height: 100%;
    width: 100%;

    > .form-box {
        height: 100%;
        padding: 0 10px 20px 10px;
        overflow-y: auto;

        .module-title {
            width: 100%;
            display: flex;
            align-items: center;

            .serial-number {
                width: 40px;
                height: 40px;
                font-size: 18px;
                color: #36414e;
                border: 1px solid #e3e3e3;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .name {
                font-size: 20px;
                margin-left: 20px;
                color: #36414e;
            }
        }

        .item-box {
            padding: 0 10px;
            max-width: 450px;
            box-sizing: border-box;

            &.item-title {
                width: 100% !important;
                padding: 40px 10px 20px 10px !important;
                flex: auto !important;
                max-width: initial !important;

                @media (min-width: 1000px) {
                    padding: 40px 10px 20px 10px !important;
                }

                @media (max-width: 1000px) {
                    padding: 20px 10px 5px 10px !important;
                }
            }

            > .title {
                margin: 15px 0 5px 0;
                font-size: 15px;
                color: #888;
            }

            .input-box {
                position: relative;
                width: 100%;

                input {
                    height: 100%;
                }

                .input {
                    position: relative;
                    height: 45px;
                    width: 100%;
                    padding: 0 50px 0 12px;
                    z-index: 10;
                    box-sizing: border-box;
                    border-radius: 6px;
                    transition: all 0.3s ease;
                    background: #fff;
                    font-size: 14px;

                    .border;
                    .flex;
                    .flex-center-items;

                    &:hover,
                    &:focus {
                        border-color: #b3b3b3;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    }

                    input {
                        font-size: 14px;
                    }

                    .placeholder {
                        color: #888;
                    }
                }

                .icon-box {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 15;

                    .icon {
                        font-size: 20px;
                    }
                }
            }

            .checkbox-box,
            .radio-box {
                display: inline-block;
            }

            .child-box {
                .item {
                    display: inline-block;
                    line-height: 28px;
                    font-size: 14px;
                }

                .input {
                    width: 100px;
                    margin: 2px 10px;
                    padding: 0 5px;
                    border: initial;
                    border-bottom: 1px solid #e3e3e3;
                    text-align: center;
                }
            }
        }
    }

    > .submit-btn-box {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 120;
        display: flex;
        justify-content: center;
        background: linear-gradient(transparent, rgba(255, 255, 255, 0.7));

        .submit-btn {
            margin: 0 15px 15px 15px;
            height: 45px;
            max-width: 400px;
            background: #14b16e;
            color: #fff;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            border: 0;
            width: calc(100% - 30px);
            cursor: pointer;

            .flex;
            .flex-center-all;

            &::after {
                border: initial;
            }
        }
    }

    .ivu-date-picker {
        width: 100%;
    }

    .ivu-input-prefix i,
    .ivu-input-suffix i {
        line-height: 40px;
    }

    .ivu-select-single .ivu-select-selection,
    .ivu-input {
        padding: 4px 12px;
        height: 40px;

        &:hover,
        &:focus {
            border-color: #b3b3b3;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }

        .ivu-select-placeholder,
        .ivu-select-selected-value {
            padding-left: 0;
        }
    }

    input::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #e1e1e1;
    }
    input::-moz-placeholder {
        /* Firefox 19+ */
        color: #e1e1e1;
    }
    input:-ms-input-placeholder {
        /* IE 10+ */
        color: #e1e1e1;
    }
    input:-moz-placeholder {
        /* Firefox 18- */
        color: #e1e1e1;
    }

    textarea::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #e1e1e1;
    }
    textarea::-moz-placeholder {
        /* Firefox 19+ */
        color: #e1e1e1;
    }
    textarea:-ms-input-placeholder {
        /* IE 10+ */
        color: #e1e1e1;
    }
    textarea:-moz-placeholder {
        /* Firefox 18- */
        color: #e1e1e1;
    }

    .ivu-select-placeholder {
        color: #e1e1e1 !important;
    }

    .ivu-select-disabled .ivu-select-selection,
    .ivu-input[disabled],
    fieldset[disabled] .ivu-input {
        background: #fff;
        color: initial;
    }

    &.full {
        .form-box {
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;

            > .item-box {
                max-width: initial;

                @media (max-width: 900px) {
                    width: 100%;
                }

                @media (min-width: 900px) {
                    width: 50%;
                }

                @media (min-width: 1200px) {
                    width: 33.33%;
                }

                @media (min-width: 1800px) {
                    width: 25%;
                }

                @media (min-width: 2000px) {
                    width: 500px;
                }
            }
        }
    }
}
</style>
