<template>
    <div>
        <Select v-model="selectValue" :disabled="disabled" transfer>
            <Option v-for="item in select_options" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
        <ElemInput v-show="isDisplayInput" style="margin-top:10px" :value="inputValue" :disabled="disabled" @on-input="inputValue = $event.value"></ElemInput>
    </div>
</template>

<script>
import ElemInput from "./ElemInput.vue"
import Queue from "../utils/queue"

export default {
    components: { ElemInput },

    props: {
        name: String,

        mode: {
            type: String,
            default: "common",
        },

        options: {
            type: Array,
            required: false,
        },

        value: {
            type: String,
            default: "",
        },

        // 是否禁用
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            select_options: null,
            // 显示选择其他后显示输入框
            isDisplayInput: false,
            // 输入框内容
            inputValue: "",
            // 选择器值
            selectValue: null,
        }
    },

    watch: {
        options: {
            handler() {
                this.setOptionsQueue()
            },
            immediate: true,
        },

        mode: {
            handler() {
                this.setOptionsQueue()
            },
            immediate: true,
        },

        value: {
            handler(v, o) {
                if (v === "" || v === o || !this.options) return

                if (!v) {
                    this.isDisplayInput = false
                    this.selectValue = null
                    this.inputValue = ""
                    return
                }

                if (!this.isDisplayInput && this.options.findIndex(res => res.value === v) > -1) {
                    this.selectValue = v
                } else {
                    this.isDisplayInput = true
                    this.selectValue = this.other
                    this.inputValue = v
                }
            },
            immediate: true,
        },

        selectValue(v) {
            if (this.other && this.other === v) {
                this.isDisplayInput = true
                v = ""
            } else {
                this.isDisplayInput = false
            }

            this.$emit("on-change", {
                tag: "ElemSelect",
                value: v,
                name: this.name,
            })
        },

        inputValue(v) {
            console.log(v)
            this.$emit("on-change", {
                tag: "ElemSelect",
                value: v,
                name: this.name,
            })
        },
    },

    methods: {
        setOptionsQueue() {
            if (!this.initial) {
                this.setOptions()
            }

            Queue.line("ELEM_SELECT_SET_OPTIONS", async next => {
                await this.setOptions()
                next()
            })
        },

        async setOptions() {
            if (this.mode === "nation") {
                if (!this.nations) {
                    await this.getNationData()
                } else if (this.nations !== this.select_options) {
                    this.select_options = this.nations
                }
            } else {
                let extend = this.options.find(v => v.extend === "input")

                if (extend) {
                    this.other = extend.value
                }

                this.select_options = this.options
            }
        },

        /**
         * 获取民族数据
         */
        getNationData() {
            return this.$get("/datamsg/api/common/sys/findDictByDictType", {
                dictType: "NATIONAKLITY",
            }).then(res => {
                if (res.code == 200) {
                    this.nations = this.select_options = res.dataList.map(item => {
                        return {
                            value: item.dictValue,
                            label: item.dictValue,
                        }
                    })
                } else {
                    this.$Message.error({
                        background: true,
                        content: "获取民族信息失败",
                    })
                }
            })
        },
    },
}
</script>

<style></style>
