export default class Utils {
    static getUuid(len = 32) {
        var $chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz"
        var maxPos = $chars.length
        var pwd = ""
        for (let i = 0; i < len; i++) {
            pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
        }
        return pwd
    }

    /**
     * 仿 forEach 循环
     * @param a 数组
     * @param cb 回调函数
     * @param condition 条件
     */
    static each(a, cb, condition) {
        if (!a || !(a instanceof Array)) return []

        for (let i = 0, len = a.length; i < len; i++) {
            if (condition ? !condition(a[i]) : false) {
                continue
            }

            let _cb = cb(a[i], i, i === len - 1)

            if (_cb === "delete") {
                a.splice(i, 1)
                i-- // 下标自减
                len-- // 总长度自减
            } else if (_cb === "break") {
                break
            } else if (_cb === "move-after") {
                i < len - 1 && (a[i] = a.splice(i + 1, 1, a[i])[0])
                break
            } else if (_cb === "move-before") {
                i > 0 && (a[i] = a.splice(i - 1, 1, a[i])[0])
                break
            } else if (_cb) {
                a[i] = _cb
            }
        }

        return a
    }

    /**
     * 对象 遍历
     * @param obj 对象
     * @param cb 回调函数
     * @param condition 条件
     */
    static eachObj(obj, cb, condition) {
        if (!obj || typeof obj !== "object") {
            return obj
        }

        for (let i = 0, keys = Object.keys(obj), len = keys.length; i < len; i++) {
            let key = keys[i]

            if (condition ? !condition(obj[key]) : false) {
                continue
            }

            let _cb = cb(key, obj[key], i, i === len - 1)

            if (_cb === "delete") {
                delete obj[key]
            } else if (_cb === "break") {
                break
            } else if (typeof _cb !== "undefined") {
                obj[key] = _cb
            }
        }

        return obj
    }

    /**
     * 查找数组指定元素
     * @param d 数组
     * @param cb 回调函数
     */
    static find(d, condition, cb) {
        if (d)
            for (let i = 0, l = d.length; i < l; i++) {
                const _cb = condition(d[i])

                if (_cb) {
                    const res = { i, data: d[i] }

                    // 回调处理事件
                    cb && cb(res)

                    // 返回结果
                    return res
                }
            }

        return { i: -1, data: null }
    }

    /**
     * 拷贝对象，根据 Key 值
     * @param {Object} target 目标对象
     * @param {Object} source 源对象
     * @param  {...String} keys key
     */
    static copyObjcetByKeys(target, source, ...keys) {
        if (!target || !source) return

        // key 为空的时候获取源对象的所有 key
        if (keys.length <= 0) {
            keys = Object.keys(source)
        }

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i]
            let val = Utils.getStringObject(source, key)
            // 空值就跳过
            if (this.isBlank(val)) continue
            // 获取源对象所对应的属性值后赋值给目标对象
            Utils.setStringObject(target, key, val)
        }
    }

    /**
     * 写入对象值
     * @param o 对象
     * @param str 字符串 key
     * @param val 值
     */
    static setStringObject(o, str, val) {
        if (str.indexOf(".") <= -1) {
            o[str] = val
        } else {
            var strs = str.split(".")

            for (let i = 0, l = strs.length; i < l; i++) {
                var s = strs[i]

                if (i === l - 1) {
                    if (/.*\[\d+\]$/.test(strs[i - 1])) {
                        let idx = strs[i - 1].replace(/.*\[(\d+)\]$/, "$1")

                        o[idx] ? (o[idx][s] = val) : (o[idx] = val)
                    } else {
                        o[s] = val
                    }
                } else {
                    if (/.*\[\d+\]$/.test(s)) {
                        s = s.replace(/\[\d+\]$/, "")

                        if (!o[s]) {
                            o[s] = []
                        }

                        o = o[s]
                    } else {
                        if (!o[s]) {
                            o[s] = {}
                        }

                        o = o[s]
                    }
                }
            }
        }
    }

    /**
     * 获取对象值 根据 字符串 key
     * @param {*} o 对象
     * @param {*} str 字符串 key, 例如 student
     * @returns
     */
    static getStringObject(o, str) {
        if (!o || !str) {
            return null
        }

        if (str.indexOf(".") <= -1) {
            if (/.*\[\d+\]$/.test(str)) {
                str.replace(/(.*)\[(\d+)\]$/, "$1")
                return o[RegExp.$1][RegExp.$2]
            } else {
                return o[str]
            }
        } else {
            var strs = str.split(".")

            for (let i = 0, l = strs.length; i < l; i++) {
                var s = strs[i]

                if (i === l - 1) {
                    if (/.*\[\d+\]$/.test(strs[i - 1])) {
                        let idx = strs[i - 1].replace(/.*\[(\d+)\]$/, "$1")

                        return o[idx]
                    } else {
                        return o[s]
                    }
                } else {
                    if (/.*\[\d+\]$/.test(s)) {
                        s = s.replace(/\[\d+\]$/, "")

                        if (!o[s]) {
                            return null
                        }

                        o = o[s]
                    } else {
                        if (!o[s]) {
                            return null
                        }

                        o = o[s]
                    }
                }
            }
        }
    }

    static verify(type, value) {
        let verify_type = {
            english: /^[^\u4e00-\u9fa5]+$/g,
            phone: /^1\d{10}$/,
            email: /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/,
            id_number: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            password: /^(?![^a-zA-Z]+$)(?!\D+$).{8,15}$/,
            location: /^(\\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,15})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,15}|180)$/,
        }

        if (verify_type[type]) {
            return new RegExp(verify_type[type]).test(value)
        }

        return true
    }

    /**
     * 判断是否为空
     * @param target 目标值
     */
    static isBlank(target) {
        if ("undefined" === typeof target || target === null) {
            return true
        }

        if ("number" === typeof target || "boolean" === typeof target) {
            return false
        }

        // 是否为字符串值，且全部为空格
        if ("string" === typeof target && /^[ ]+$/.test(target)) {
            return true
        }

        // 为数组时，长度小于1，认定为空
        if (target instanceof Array && target.length <= 0) {
            return true
        }

        return !target
    }

    /**
     * 判断是否存在
     * @param target 目标值
     */
    static isExist(target) {
        return !this.isBlank(target)
    }

    /**
     * 对象转 URL 参数
     * @param obj 对象
     * @param url 请求链接（可选，用于判断链接是否带参数）
     */
    static jsonToParams(json, url) {
        var s = ""
        var p = url ? /\?/.test(url) : false

        this.each(Object.keys(json), n => {
            let v = json[n]

            if ("undefined" !== typeof v) {
                s += `${s.length === 0 && !p ? "?" : "&"}${n}=${v}`
            }
        })

        return url ? url + s : s
    }

    static paramsToJson(url) {
        // 没有参数，返回空对象
        if (!url || !/\?/.test(url)) {
            return {}
        }

        var parames = {}

        url = decodeURI(url)

        var vars = url.substring(url.indexOf("?") + 1).split("&")

        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=")
            parames[pair[0]] = pair[1]
        }

        return parames
    }
}
