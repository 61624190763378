import { Message } from "view-design"

export default class Loading {
    static __loading_idx = 0

    static show() {
        if (this.__loading_idx++ > 0) return

        this.__message = Message.loading({
            content: "加载中...",
            duration: 60,
        })
    }

    static hide() {
        setTimeout(() => {
            if (--this.__loading_idx <= 0) {
                this.__message?.()
                this.__loading_idx = 0
            }
        }, 100)
    }
}
