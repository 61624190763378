<template>
    <div class="elem-switch-box" :class="['elem-switch-box-size-' + size, { disabled: disabled }]">
        <div class="switch-box" :class="val ? 'switch-on' : 'switch-off'" :style="{ 'border-radius': radius + 'px' }">
            <div class="switch-btn on-btn" @click="onChangeSwitch(true)">{{ keyOn }}</div>
            <div class="switch-btn off-btn" @click="onChangeSwitch(false)">{{ keyOff }}</div>
            <div class="block" :style="{ 'border-radius': radius - 2 + 'px' }"></div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            radius: null,
            val: null,
        }
    },

    props: {
        keyOn: {
            type: String,
            default: "ON",
        },
        keyOff: {
            type: String,
            default: "OFF",
        },
        valueOn: {
            type: null,
            default: true,
        },
        valueOff: {
            type: null,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: "switch",
        },
        value: {
            type: null,
            default: false,
        },
        // 是否禁用
        disabled: {
            type: Boolean,
            default: false,
        },

        // 大小，小：small 中：medium
        size: {
            type: String,
            default: "medium",
        },
        // 形状，圆：circle 方：square
        shape: {
            type: String,
            default: "circle",
        },
    },

    watch: {
        val(v) {
            this.$emit("on-change", {
                value: v ? this.valueOn : this.valueOff,
                type: "ElemSwitch",
                name: this.name,
                restore: () => {
                    this.val = !v
                },
            })
        },
        value: {
            handler(v) {
                this.val = v === this.valueOn
            },
            immediate: true,
        },
    },

    created() {
        if (this.shape === "circle") {
            this.radius = this.size === "medium" ? 20 : 15
        } else {
            this.radius = 6
        }
    },

    methods: {
        onChangeSwitch(val) {
            // 禁用模式
            if (this.disabled) return

            if (val != this.val) {
                this.val = val
            }
        },
    },
}
</script>

<style lang="less">
@import (reference) "../style/utils.less";

.elem-switch-box {
    .switch-box {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        transition: all 0.3s ease;
        display: flex;
        background: #f3f3f3;
        border-radius: 6px;

        &:hover {
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) inset;

            .block {
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
            }
        }
    }

    .switch-btn {
        cursor: pointer;
        position: relative;
        width: 50%;
        height: 100%;
        z-index: 30;
        display: flex;
        align-items: center;
        color: #666;
        padding: 10px 14px;
        box-sizing: border-box;
    }

    .off-btn {
        justify-content: flex-end;
    }

    .block {
        position: absolute;
        top: 5px;
        right: 50%;
        bottom: 5px;
        left: 5px;
        background: #fff;
        border-radius: 4px;
        z-index: 20;
        transition: all 0.3s ease;
    }

    .switch-on {
        background: #00b3d9;

        .off-btn {
            color: #d3d3d3;
        }
    }

    .switch-off {
        .on-btn {
            color: #d3d3d3;
        }

        .block {
            left: 50%;
            right: 5px;
        }
    }

    &.disabled .switch-btn {
        cursor: no-drop !important;
    }
}

.elem-switch-box-size-medium {
    width: 120px;
    height: 40px;
}

.elem-switch-box-size-small {
    width: 100px;
    height: 30px;
}

.elem-switch-box-size-small .switch-btn {
    font-size: 12px;
}

.elem-switch-box-dark {
    .switch-box {
        background: #2c2c2e;
    }

    .switch-on {
        background: #0a5495;
    }

    .block {
        background: #e3e3e3;
    }
}
</style>
