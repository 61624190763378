<template>
    <div class="elem-mobile-base">
        <div class="elem-mobile-box" v-for="(item, idx) in mobiles" :key="item.key">
            <input ref="input" :maxlength="max" type="text" class="input" :name="name" :disabled="disabled" placeholder="请输入" :value="item.value" @input="onInput($event, idx)" />
            <block v-if="multiple">
                <div class="operate-btn" v-if="idx === 0" @click="!disabled && onAddItem()" :style="{ cursor: disabled ? 'no-drop' : 'pointer' }">
                    <Icon class="icon" type="md-add" color="#fff" />
                </div>
                <div class="operate-btn remove" v-else @click="!disabled && onRemoveItem(idx)" :style="{ cursor: disabled ? 'no-drop' : 'pointer' }">
                    <Icon class="icon" type="md-remove" color="#fff" />
                </div>
            </block>
        </div>
    </div>
</template>

<script>
import Utils from "../../jointly/utils/utils"
export default {
    data() {
        return {
            mobiles: [],
        }
    },

    props: {
        max: {
            type: Number,
            default: 255,
        },
        name: String,
        title: String,
        placeholder: {
            type: String,
            default: "",
        },
        value: {
            type: String,
            default: "",
        },
        // 是否禁用
        disabled: {
            type: Boolean,
            default: false,
        },
        // 多选
        multiple: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        value: {
            handler(val) {
                if (val === this.val) return

                const arr = []
                val = this.parse(val)

                if (val) {
                    if (typeof val === "string") {
                        val = /^\[.+\]$/.test(val) ? JSON.parse(val) : val.split(",")
                    }

                    for (let i = 0; i < val.length; i++) {
                        arr.push({
                            key: Utils.getUuid(),
                            value: val[i],
                        })
                    }
                }

                this.mobiles = arr.length > 0 ? arr : [{ key: Utils.getUuid() }]

                this.$emit("on-input", {
                    value: this.getValue(),
                    tag: "ElemMobile",
                    name: this.name,
                })
            },
            immediate: true,
        },
    },

    methods: {
        parse(val) {
            if (typeof val === "string") {
                if (/^[\\{\\[].*[\]\\}]$/.test(val)) {
                    return JSON.parse(val)
                } else if (~val.indexOf("|")) {
                    return val.split("|")
                }
            }
            return val
        },

        onInput(evt, idx) {
            this.setValue(evt.target.value, idx)

            this.$emit("on-input", {
                value: this.getValue(),
                tag: "ElemMobile",
                name: this.name,
            })
        },

        setValue(value, idx) {
            this.mobiles[idx].value = value
        },

        getValue() {
            var res = []

            for (let i = 0, ms = this.mobiles; i < ms.length; i++) {
                const v = ms[i]

                if (v.value) {
                    res.push(v.value)
                }
            }

            if (!this.multiple) {
                res = res?.[0]
            }

            this.val = res

            return this.val
        },

        /**
         * 添加元素
         */
        onAddItem() {
            this.mobiles.push({
                key: Utils.getUuid(),
            })
        },

        /**
         * 删除元素
         */
        onRemoveItem(idx) {
            this.mobiles.splice(idx, 1)

            this.$emit("on-input", {
                value: this.getValue(),
                tag: "ElemMobile",
                name: this.name,
            })
        },
    },
}
</script>

<style lang="less">
@import (reference) "../style/utils.less";

.elem-mobile-base {
    .elem-mobile-box {
        position: relative;
        width: 100%;
        height: 40px;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        .input {
            position: relative;
            height: 40px;
            padding: 0 50px 0 12px;
            font-size: 14px;
            z-index: 10;
            box-sizing: border-box;
            border-radius: 6px;
            transition: all 0.3s ease;
            background: #fff;
            border: 1px solid #e3e3e3;
            width: 100%;

            &:hover,
            &:focus {
                border-color: #b3b3b3;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }
        }

        .operate-btn {
            position: absolute;
            top: 7px;
            right: 7px;
            bottom: 7px;
            width: 40px;
            background: #2faaf7;
            color: #fff;
            font-size: 20px;
            z-index: 20;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;

            &.remove {
                background: #ff5722;
            }
        }
    }
}
</style>
