<template>
    <div class="elem-input-box" :class="{ disabled }">
        <input ref="input" autocomplete="new-password" :maxlength="max" :type="type" class="input" :verify="verify" :title="title" :required="required" :name="name" :disabled="disabled" :placeholder="placeholder" v-model="val" @change="onChange" @input="onInput" />
        <div class="unit" v-if="unit">
            <span style="margin-right: 5px;">/</span>
            {{ unit }}
        </div>
        <div class="icon-box" v-show="check !== null">
            <Icon v-show="check === true" size="24" color="#2faaf7" type="ios-checkmark-circle" />
            <Icon v-show="check === false" size="24" color="#d6626e" type="ios-close-circle" />
        </div>
    </div>
</template>

<script>
import Utils from "../utils/utils"

export default {
    data() {
        return {
            check: null,
            val: null,
            place_holder: null,

            language: {
                enter: "输入",
                optional: "可选",
            },
        }
    },

    props: {
        type: {
            type: String,
            default: "text",
        },
        max: {
            type: Number,
            default: 255,
        },
        name: String,
        title: String,
        // 校验类型
        verify: String,
        required: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: "请输入",
        },
        value: {
            type: String,
            default: "",
        },
        // 是否禁用
        disabled: {
            type: Boolean,
            default: false,
        },
        // 单位显示
        unit: {
            type: String,
            required: false,
        },
    },

    watch: {
        value: {
            handler(val) {
                if (/^\[.*\]$/.test(val)) val = JSON.parse(val)
                // 如果为数组，取第一个元素
                if (val instanceof Array) val = val?.[0]

                this.val = val
            },
            immediate: true,
        },
        val: {
            handler(value) {
                if (value) {
                    this.check = Utils.verify(this.verify, value)
                } else {
                    this.check = null
                }
            },
            immediate: true,
        },
        verify: {
            handler(value) {
                if (this.val) {
                    this.check = Utils.verify(value, this.val)
                } else {
                    this.check = null
                }
            },
            immediate: true,
        },
    },

    mounted() {
        // this.place_holder = this.placeholder ||
    },

    methods: {
        onChange(evt) {
            this.$emit("on-change", {
                value: evt.target.value,
                type: "elem-input",
                name: this.name,
                set: value => {
                    this.val = value
                },
            })
        },

        onInput(evt) {
            this.$emit("on-input", {
                value: evt.target.value,
                type: "elem-input",
                name: this.name,
                set: value => {
                    this.val = value
                },
            })
        },

        getValue() {
            const value = this.val

            if (Utils.isBlank(value) && this.required) {
                this.check = false
            }

            return value
        },
    },
}
</script>

<style lang="less">
@import (reference) "../style/utils.less";

.elem-input-box {
    position: relative;
    height: 40px;
    width: 100%;
    padding: 0 10px 0 10px;
    z-index: 10;
    box-sizing: border-box;
    border-radius: 6px;
    transition: all 0.3s ease;
    background: #fff;
    display: flex;
    align-items: center;
    border: 1px solid #e3e3e3;

    &:hover,
    &:focus {
        border-color: #b3b3b3;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .input {
        flex-grow: 1;
        font-size: 14px;
        margin-right: 5px;
        border: 0;

        input {
            font-size: 14px;
        }

        &:disabled {
            background-color: #fff;
        }
    }

    .icon-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-left: 10px;
    }

    .unit {
        flex-shrink: 0;
    }

    &.disabled {
        cursor: no-drop;

        .input {
            cursor: no-drop;
        }
    }
}
</style>
