<template>
    <div class="elem-section">
        <div class="title" v-if="title">{{ title }}</div>
        <div class="range-box">
            <Input v-model="minValue" class="range" type="number" maxlength="10" placeholder="区间开始" />
            <p class="p">-</p>
            <Input v-model="maxValue" class="range" type="number" maxlength="10" placeholder="区间结束" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            minValue: "",
            maxValue: "",
        }
    },

    model: {
        prop: "value",
        event: "change",
    },

    props: {
        title: {
            type: String,
            required: false,
        },
        value: {
            type: Object,
            default: null,
        },
    },

    watch: {
        minValue(v) {
            var v = null
            if (this.minValue && this.maxValue) {
                v = {
                    from: this.minValue,
                    to: this.maxValue,
                }
            }
            this.$emit("change", v)
        },

        maxValue(v) {
            var v = null
            if (this.minValue && this.maxValue) {
                v = {
                    from: this.minValue,
                    to: this.maxValue,
                }
            }
            this.$emit("change", v)
        },

        value: {
            handler(val) {
                this.minValue = val?.from || ""
                this.maxValue = val?.to || ""
            },
            immediate: true,
        },
    },
}
</script>

<style lang="less" scope>
.elem-section {
    display: flex;
    align-items: center;

    > .title {
        margin-right: 10px;
    }

    .range-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .p {
            margin: 0 10px;
        }

        .range {
            width: 150px;
        }
    }
}
</style>
