<template>
    <DatePicker v-model="val" :type="type" placeholder="选择日期" :format="data_format" :disabled="disabled" transfer />
</template>

<script>
export default {
    data() {
        return {
            val: null,
            data_format: undefined,
        }
    },

    props: {
        value: {
            type: String || Date,
            required: false,
        },

        type: {
            type: String,
            default: "date",
        },

        name: String,

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        val(v) {
            if (!v) {
                this.format = null
            } else if (v instanceof Array) {
                this.format = v.map(i => this.$core.formatDate(i, "yyyy-MM-dd" + (~this.type.indexOf("time") ? " hh:mm:ss" : "")))
            } else {
                this.format = this.$core.formatDate(v, "yyyy-MM-dd" + (~this.type.indexOf("time") ? " hh:mm:ss" : ""))
            }

            this.$emit("on-change", {
                tag: "ElemDate",
                value: this.format,
                name: this.name,
            })
        },

        value: {
            handler(v) {
                if (!v) {
                    return this.val && (this.val = null)
                }

                if (v === this.format) return

                if (typeof v === "string") {
                    // 校验日期格式是否正确
                    // if (!/^[0-9]+(-|\/)[0-9]+(-|\/)[0-9]+$/.test(v)) return
                    // new 日期对象
                    v = new Date(v)
                }

                this.val = v
            },
            immediate: true,
        },

        type: {
            handler(v) {
                switch (v) {
                    case "datetimerange":
                        this.data_format = "yyyy-MM-dd HH:mm"
                        break
                }
            },
            immediate: true,
        },
    },
}
</script>

<style></style>
