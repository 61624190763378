<template>
    <div class="elem-label-box">
        <div :class="[type === 'input' ? 'input-label-box' : 'textarea-label-box']">
            <div class="label-box" v-if="labels && labels.length > 0">
                <div class="label-item-box" v-for="(item, idx) in labels" :key="idx">
                    <p class="value">{{ item }}</p>
                    <div class="delete-btn" @click.stop="onDeleteLabel(idx)">
                        <Icon type="md-close" />
                    </div>
                </div>
            </div>
            <div class="input-box" v-if="type === 'input'">
                <input class="input" type="text" @keyup.enter="onAppendLabel" :placeholder="placeholder" @blur="onBlur" />
            </div>
            <div class="textarea-box" v-else>
                <textarea class="textarea" @keyup.enter="onAppendLabel" :placeholder="placeholder" @blur="onBlur"></textarea>
            </div>
        </div>
    </div>
</template>

<script>
import Utils from "../utils/utils"

export default {
    data() {
        return {
            labels: [],
        }
    },

    props: {
        required: {
            type: Boolean,
            default: true,
        },
        title: String,
        placeholder: {
            type: String,
            default: "请输入",
        },
        name: String,
        value: Array,
        type: {
            type: String,
            default: "textarea",
        },
    },

    model: {
        prop: "value",
        event: "change",
    },

    watch: {
        labels(v) {
            this.$emit("change", v)

            this.$emit("on-change", {
                tag: "ElemLabel",
                name: this.name,
                value: v,
            })
        },

        value(v) {
            if (!v) {
                return (this.labels = [])
            }

            if (typeof v == "string") {
                v = /^\[.*\]$/.test(v) ? JSON.parse(v) : v.split(",")
            }

            this.labels = v
        },
    },

    mounted() {
        if (Utils.isExist(this.value)) {
            this.labels = this.value
        }
    },

    methods: {
        onAppendLabel(evt) {
            const input = Utils.getTarget(evt)
            const value = input.value

            if (value) {
                this.labels.push(value.replace(/[\r\n]/g, ""))
            }

            input.value = ""
        },

        onDeleteLabel(idx) {
            this.labels.splice(idx, 1)
        },

        onBlur(evt) {
            this.onAppendLabel(evt)
        },

        getValue() {
            return this.labels
        },
    },
}
</script>

<style lang="less">
.elem-label-box {
    width: 100%;
    font-size: 14px;
    z-index: 10;
    box-sizing: border-box;
    border-radius: 6px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    overflow: hidden;
    border: 1px solid #d9d9d9;
}

.elem-label-box:hover {
    border-color: #b3b3b3;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.elem-label-box .label-textarea {
    display: none;
}

.input-label-box {
    width: 100%;
    height: 40px;
    padding: 0 50px 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.elem-label-box .label-box {
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.elem-label-box .label-box .label-item-box {
    margin-right: 10px;
    padding: 6px 5px 6px 10px;
    display: flex;
    align-items: center;
    background: #2faaf7;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease;
    max-width: 100%;
    word-break: break-word;

    .value {
        font-size: 14px;
        color: #fff;
        line-height: 1;
    }
}

.elem-label-box .label-box .label-item-box:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.elem-label-box .label-box .label-item-box .delete-btn {
    cursor: pointer;
    padding: 0 5px;
    font-size: 18px;
    display: flex;
    box-sizing: content-box;
    color: #fff;
}

.elem-label-box .input-box {
    flex-grow: 1;
}

.textarea-label-box {
    width: 100%;

    .label-box {
        padding: 5px;
        border-bottom: 1px solid #d9d9d9;
        flex-wrap: wrap;

        .label-item-box {
            margin: 5px;
        }
    }

    .textarea-box {
        padding: 5px;
    }
}

.textarea-label-box .textarea-box .textarea {
    height: 100px;
    min-width: 100%;
    max-width: 100%;
    min-height: 50px;
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
    border: 0;
}
</style>
