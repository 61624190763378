<template>
    <div>
        <comp-form
            title="导购机（导购员）"
            ref="comp_form"
            :isJson="false"
            submit-api="/gateway/apps/o2o/api/terminal/addTerminal"
            edit-api="/gateway/apps/o2o/api/terminal/saveTerminal"
            detail-api="/gateway/apps/o2o/api/terminal/getTerminalByCode"
            id-key="code"
            :submit-before="onSubmitBefore"
            :dataBefore="onDataBefore"
            @on-submit="onReset"
        >
            <template v-slot:form-box="data">
                <ElemFormItem title="导购机UUID" name="uuid">
                    <Input style="width: 300px" maxlength="20" v-model="data.form.uuid" placeholder="请填写导购机UUID" :disabled="data.model !== 'create'"></Input>
                </ElemFormItem>
                <ElemFormItem title="导购机名称" name="terminalName">
                    <Input style="width: 300px" maxlength="20" v-model="data.form.terminalName" placeholder="请填写导购机名称"></Input>
                </ElemFormItem>
                <ElemFormItem title="经纬度">
                    <Input placeholder="请输入经度" type="number" v-model.trim="data.form.lng" :maxlength="40" style="width: 100px"></Input>
                    <Input placeholder="请输入纬度" type="number" v-model.trim="data.form.lat" :maxlength="40" style="width: 100px; margin-left: 10px"></Input>
                    <Button type="info" style="margin-left: 10px" @click="$refs.elem_map.display()">选择经纬度</Button>
                </ElemFormItem>
                <ElemFormItem title="绑定群组" name="terminalGroupId">
                    <Select v-model="data.form.terminalGroupId" filterable style="width: 300px">
                        <Option v-for="item in groups" :value="item.id" :key="item.id">{{ item.groupName }}</Option>
                    </Select>
                </ElemFormItem>

                <ElemFormItem title="导购员" name="seller">
                    <elem-select
                        style="width: 300px"
                        placeholder="请输入用户名或手机号码进行搜索"
                        :remotely="{ api: '/gateway/apps/basic/api/base/uc/getByMobileOrNickname', search: 'mobileOrNickname', id: 'globalId', value: 'nickName' }"
                        :value="data.form.seller"
                        @on-change="
                            data.form.seller = $event.value
                            data.form.seller_name = $event.title
                        "
                    ></elem-select>
                </ElemFormItem>

                <ElemFormItem title="描述" name="applyDesc" :required="false">
                    <Input style="width: 300px" type="textarea" v-model="data.form.applyDesc" placeholder="请填写描述"></Input>
                </ElemFormItem>

                <ElemFormItem title="启禁用" name="idisplay">
                    <RadioGroup v-model="data.form.idisplay">
                        <Radio :label="1">启用</Radio>
                        <Radio :label="0">禁用</Radio>
                    </RadioGroup>
                </ElemFormItem>

                <LiefengModal class="map" :fullscreen="false" width="80%" title="选择经纬度" @input="isDisplayMap = $event" :value="isDisplayMap">
                    <template #contentarea style="width: 100%; height: 700px">
                        <Map :showNum="showNum" :localName="localName" :center="center" @clickMap="onClickMap"></Map>
                    </template>
                    <template #toolsbar>
                        <Button type="info" style="margin-right: 10px" @click="isDisplayMap = false">取消</Button>
                        <Button type="primary" @click="onSaveLocation">确定</Button>
                    </template>
                </LiefengModal>
            </template>
        </comp-form>

        <ElemMap ref="elem_map" modal @on-submit="onSubmitLocation"></ElemMap>
    </div>
</template>

<script>
import CompForm from "../../../jointly/components/CompForm.vue"
import LiefengModal from "@/components/LiefengModal"
import ElemMap from "../../../jointly/components/ElemMap.vue"
import ElemSelect from "../../components/elem-select.vue"
import ElemFormItem from "../../../jointly/components/elem-form-item.vue"

export default {
    components: { CompForm, ElemFormItem, LiefengModal, ElemSelect, ElemMap },

    data() {
        return {
            groups: null,
            isDisplayMap: false,
            selectList: [],
            orgCodeSelf: "",
        }
    },

    mounted() {
        this.getGroup()
    },

    methods: {
       
        getGroup() {
            this.$get("/gateway/apps/o2o/api/terminal/goods/getTerminalGroupOptions", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    this.groups = res.dataList
                }
            })
        },

        /**
         * 打开表单窗口
         */
        open(id) {
            this.orgCodeSelf = ''
            this.$refs.comp_form.open(id)
        },

        /**
         * 重置事件
         */
        onReset() {
            this.$emit("on-reset")
        },

        /**
         * 数据前置事件
         */
        onDataBefore(data) {
            if (data.salesGlobalId && data.salesUsername) {
                data.seller = {
                    id: data.salesGlobalId,
                    value: data.salesUsername,
                }
            }
            data.orgCodeSelf = this.orgCodeSelf

            return data
        },

        /**
         * 提交数据前置事件
         */
        onSubmitBefore(data) {
            data.oemCode = parent.vue.oemInfo.oemCode
            data.updatedBy = parent.vue.loginInfo.userinfo.custGlobalId

            data.globalId = data.seller
            data.userName = data.seller_name
            data.orgCodeSelf = this.orgCodeSelf
            return data
        },

        onClickMap(evt) {
            this.location = evt
        },

        onSaveLocation() {
            if (!this.location) {
                return this.$Message.error({
                    content: "请选择坐标",
                    background: true,
                })
            }

            this.$refs.comp_form.setValue({
                lng: this.location.lng,
                lat: this.location.lat,
            })

            this.isDisplayMap = false
        },

        onSubmitLocation(evt) {
            this.$refs.comp_form.setValue({
                lng: evt.value.location.longitude,
                lat: evt.value.location.latitude,
            })
        },
    },
}
</script>
