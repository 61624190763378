export default {
    name: "RenderHeader",
    functional: true,
    props: {
        render: Function,
        params: Object,
    },
    render: (h, ctx) => {
        var cb

        if (typeof ctx.props.render === "function") {
            cb = ctx.props.render(h, ctx.props.params)
        } else {
            cb = ctx.props.render
        }

        return typeof cb === "object"
            ? cb
            : h("div", {
                  domProps: {
                      innerHTML: cb,
                  },
              })
    },
}
