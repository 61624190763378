<template>
    <CompModal ref="comp_modal" title="导出" class="comp-tabular" width="700px">
        <div class="tabular-page" ref="tabular_page">
            <div class="title">{{ title }}</div>
            <div class="tabular-box">
                <div class="item-box" v-for="(col, idx) in columns" :key="idx">
                    <div class="title-box" v-if="col.title">{{ col.title }}</div>
                    <div class="col-box" :class="{ row: col.sort === 'row' }" v-if="col.child">
                        <div class="col-item" v-for="(col_item, col_idx) in col.child" :key="col_idx">
                            <div class="row-box" v-if="col_item.child">
                                <div class="row-item" v-for="(row_item, row_idx) in col_item.child" :key="row_idx">
                                    <div class="value-box" contenteditable>
                                        <ElemRender :render="row_item.value" :params="value"></ElemRender>
                                    </div>
                                </div>
                            </div>
                            <div class="value-box" v-else contenteditable>
                                <ElemRender :render="col_item.value" :params="value"></ElemRender>
                            </div>
                        </div>
                    </div>
                    <div class="col-box" v-else>
                        <div class="value-box" contenteditable>
                            <ElemRender :render="col.value" :params="value"></ElemRender>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template #operate>
            <div class="operate-btns">
                <Button class="operate-btn" @click="onExport('photo')">下载图片</Button>
                <Button class="operate-btn highlight" type="primary" @click="onExport('pdf')">打印/导出PDF</Button>
            </div>
        </template>
    </CompModal>
</template>

<script>
import CompModal from "../../residentdatabase/components/CompModal.vue"
import ElemRender from "../../communitymobilization/components/header"

import FileUtil from "../utils/file"

export default {
    components: { CompModal, ElemRender },

    data() {
        return {
            value: {},
        }
    },

    props: {
        title: String,
        columns: {
            type: Array,
            required: false,
        },
    },

    methods: {
        display(value = {}, columns) {
            if (columns) {
                this.columns = columns
            }
            this.value = value
            this.$refs.comp_modal.display()
        },

        onExport(type) {
            const e = this.$refs.tabular_page,
                t = this.title
            type === "photo" ? FileUtil.exportToPhoto(e, t) : FileUtil.exportToPDF(e)
        },
    },
}
</script>

<style lang="less">
@border-color: #e3e3e3;

.comp-tabular {
    .tabular-page {
        padding: 20px;

        .title {
            width: 100%;
            line-height: 1;
            font-size: 18px;
            font-weight: 500;
            color: #333;
            text-align: center;
        }

        .tabular-box {
            margin-top: 20px;
            width: 100%;
            border: 1px solid @border-color;

            .item-box {
                display: flex;
                border-bottom: 1px solid @border-color;

                &:last-child {
                    border-bottom: 0;
                }

                .title-box {
                    padding: 10px;
                    width: 110px;
                    border-right: 1px solid @border-color;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-shrink: 0;
                    text-align: center;
                }

                .col-box {
                    flex-grow: 1;

                    .col-item {
                        border-bottom: 1px solid @border-color;

                        &:last-child {
                            border-bottom: 0;
                        }

                        .row-box {
                            width: 100%;
                            height: 100%;
                            display: flex;

                            .row-item {
                                border-right: 1px solid @border-color;
                                flex: 1;

                                &:last-child {
                                    border-right: 0;
                                }
                            }
                        }
                    }

                    .value-box {
                        width: 100%;
                        height: 100%;
                        padding: 10px;
                        box-sizing: border-box;
                    }

                    &.row {
                        display: flex;

                        .col-item {
                            border-right: 1px solid @border-color;
                            border-bottom: initial;
                            flex: 1;

                            &:last-child {
                                border-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
