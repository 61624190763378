<template>
    <div class="elem-select-box" :class="{ level: unfold }">
        <div class="select-base" dark-class="select-base-dark">
            <div class="select-box" :class="{ unfold: unfold }">
                <div class="first-box">
                    <div class="label-box" v-if="multiple">
                        <div class="label-item-box" v-for="(item, idx) in label" :key="idx">
                            <p>{{ item }}</p>
                            <div class="delete-btn" @click.stop="onDeleteLabel(idx)"></div>
                        </div>
                    </div>
                    <input
                        v-model="val_text"
                        class="input"
                        :class="{ full: label && label.length > 0 }"
                        type="text"
                        :placeholder="ph"
                        @focus="onInputFocus"
                        @blur="onInputBlur"
                        @input="onSearchSelect"
                    />
                </div>
                <div class="drop-icon" v-if="multiple">
                    <Spin v-if="showLoadingSign"></Spin>
                </div>
                <div class="drop-icon" v-else>
                    <Icon v-show="val == null && !val_text" size="24" color="#666666" type="ios-arrow-down" />
                    <Icon v-show="val != null" size="24" color="#2faaf7" type="ios-checkmark-circle" />
                    <Icon v-show="val == null && val_text" size="24" color="#d6626e" type="ios-close-circle" />
                </div>
            </div>
            <div class="item-base" :class="{ unfold: unfold }">
                <div
                    class="item-box"
                    v-for="(item, idx) in data_list"
                    :key="idx"
                    :value="item.id"
                    :selected="item.id == value"
                    @click="onSelect(item.id, item.value)"
                    v-show="!search || item.value.indexOf(search) > -1"
                >
                    {{ item.value }}
                </div>
                <div v-if="data_list && data_list.length <= 0" class="empty">数据为空</div>
            </div>
        </div>
    </div>
</template>

<script>
import Headway from "../../jointly/utils/headway"
export default {
    name: "elem-select",

    data() {
        return {
            data_list: null,
            unfold: false,
            val: null,
            val_text: null,
            search: null,
            ph: "",
            label: [],
            // 显示加载中标志
            showLoadingSign: false,
        }
    },

    props: {
        data: {
            type: Array,
            required: false,
        },
        // 远程搜索接口配置
        remotely: {
            type: Object,
            required: false,
        },
        required: {
            type: Boolean,
            default: true,
        },
        title: String,
        placeholder: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            required: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        value: null,
    },

    watch: {
        val: function(value, oldValue) {
            this.onChangeData(value, oldValue)
        },
        val_text(val) {
            if (this.remotely) {
                this.onSearch(val)
            }
        },
        value: {
            async handler(value) {
                if (!value) {
                    this.val = null
                    this.label = []
                    this.val_text = ""
                    return
                }

                if (value === this.val) return

                if (this.multiple) {
                    for (let i = 0; i < value.length; i++) {
                        const v = value[i]

                        if (typeof v === "object") {
                            this.val ? this.val.push(v.id) : (this.val = [v.id])
                            this.label.push(v.value)
                            continue
                        }

                        // Can't choose repeatedly
                        if (this.val && this.val.indexOf(v) > -1) {
                            continue
                        }

                        const d = await Headway.of(this, "data_list").on()

                        for (let di = 0; di < d.length; di++) {
                            const c = d[di]

                            if (c.id == v) {
                                this.val ? this.val.push(v) : (this.val = [v])
                                this.label.push(c.value)
                            }
                        }
                    }
                } else {
                    if (typeof value === "object") {
                        this.val = value.id
                        this.val_text = value.value
                        return
                    }

                    Headway.of(this, "data_list")
                        .on()
                        .then(d => {
                            for (let di = 0; di < d.length; di++) {
                                const c = d[di]

                                if (c.id == value) {
                                    this.val = value
                                    this.val_text = c.value
                                }
                            }
                        })
                }
            },
            immediate: true,
        },

        data: {
            handler(value) {
                if (!value) return

                const data_list = []

                for (let i = 0; i < value.length; i++) {
                    const e = value[i]

                    if ("string" === typeof e) {
                        data_list.push({ id: i, value: e })
                    } else {
                        data_list.push(e)
                    }
                }

                this.data_list = data_list
            },
            immediate: true,
        },
    },

    mounted() {
        this.ph = this.placeholder ? this.placeholder : `选择${this.title || this.name}${this.required ? "" : "（可选）"}`
    },

    methods: {
        onChangeData(value, oldValue) {
            this.$emit("on-change", {
                value: value,
                title: this.multiple ? this.label : this.val_text,
                type: "elem-select",
                name: this.name,
                restore: () => {
                    this.val = oldValue
                },
            })
        },

        onInputFocus() {
            this.unfold = true
        },

        onInputBlur() {
            setTimeout(() => {
                this.unfold = false
            }, 100)
        },

        onSelect(id, val) {
            if (this.multiple) {
                // Can't choose repeatedly
                if (this.val && this.val.indexOf(id) > -1) {
                    return
                }

                this.val ? this.val.push(id) : (this.val = [id])
                this.label.push(val)
                this.val_text = ""
            } else {
                this.val = id
                this.val_text = val
            }

            setTimeout(() => {
                this.search = null
            }, 500)
        },

        onSearchSelect(evt) {
            if (this.remotely) {
                return
            }

            this.val = null

            this.search = (evt.target || evt.srcElement).value
        },

        onDeleteLabel(idx) {
            if (this.val.length > 1) {
                this.val.splice(idx, 1)
            } else {
                this.val = null
            }

            this.label.splice(idx, 1)
        },

        onSearch(val) {
            clearTimeout(this.sst)

            if (!val) return (this.data_list = null)

            this.sst = setTimeout(() => {
                this.showLoadingSign = true

                this.$get(this.remotely.api, {
                    [this.remotely.search]: val,
                })
                    .then(res => {
                        if (res.code != 200) {
                            return this.$Message.error("请求出错，请稍后再试！")
                        }

                        // 如搜索值已更改，不进行处理
                        if (this.val_text !== val) return

                        const arr = []
                        const id_key = this.remotely.id
                        const value_key = this.remotely.value

                        for (let i = 0, d = res.dataList || [res.data]; i < d.length; i++) {
                            const e = d[i]

                            arr.push({
                                id: e[id_key],
                                value: e[value_key],
                            })
                        }

                        this.data_list = arr
                    })
                    .finally(_ => {
                        this.showLoadingSign = false
                    })
            }, 300)
        },
    },
}
</script>

<style lang="less">
.elem-select-box {
    position: relative;
    width: 100%;
    z-index: 50;

    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);

    &.level {
        z-index: 60;
    }

    .select-base {
        position: relative;
        width: 100%;
        height: 100%;

        .select-box {
            background: #fff;
            border-radius: 6px;
            transition: all 0.3s ease;
            overflow: hidden;
            border: 1px solid #e3e3e3;

            &:hover {
                border-color: #b3b3b3;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                z-index: 20;
            }

            .first-box {
                position: relative;
                padding: 5px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .label-box {
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    max-width: 100%;
                    flex-wrap: wrap;

                    .label-item-box {
                        margin: 3px;
                        padding: 6px 5px 6px 10px;
                        display: flex;
                        align-items: center;
                        background: #2faaf7;
                        border-radius: 4px;
                        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        transition: all 0.3s ease;

                        &:hover {
                            box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
                        }

                        p {
                            font-size: 14px;
                            line-height: 14px;
                            color: #fff;
                        }

                        .delete-btn {
                            position: relative;
                            cursor: pointer;
                            padding: 3px 8px;
                            margin-left: 4px;
                            width: 10px;
                            height: 10px;
                            display: flex;

                            &::after {
                                content: "";
                                position: absolute;
                                left: 2px;
                                top: ~"calc(50% - 1px)";
                                height: 1px;
                                right: 2px;
                                background: #fff;
                                transform: rotate(45deg);
                                border-radius: 1px;
                            }

                            &::before {
                                content: "";
                                position: absolute;
                                left: 2px;
                                top: ~"calc(50% - 1px)";
                                height: 1px;
                                right: 2px;
                                background: #fff;
                                transform: rotate(-45deg);
                                border-radius: 1px;
                            }
                        }
                    }
                }

                .input {
                    cursor: pointer;
                    position: relative;
                    width: auto;
                    padding-right: 40px;
                    margin: 5px;
                    z-index: 10;
                    font-size: 14px;
                    flex-grow: 1;
                    border: none;
                    box-sizing: initial;
                    height: 20px;

                    &.full {
                        padding-top: 8px;
                        border-top: 1px solid #f3f3f3;
                        width: 100%;
                    }

                    &::-webkit-input-placeholder {
                        color: #c3c3c3;
                    }

                    &:-moz-placeholder {
                        color: #c3c3c3;
                    }

                    &::-moz-placeholder {
                        color: #c3c3c3;
                    }

                    &:-ms-input-placeholder {
                        color: #c3c3c3;
                    }

                    &::input-placeholder {
                        color: #c3c3c3;
                    }
                }
            }

            .drop-icon {
                position: absolute;
                top: 0;
                right: 0;
                height: 40px;
                width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 50;
                flex-shrink: 0;

                img {
                    width: 25px;
                    height: 25px;
                }
            }
        }

        .item-base {
            position: absolute;
            top: ~"calc(100% + 5px)";
            left: 0;
            width: 100%;
            max-height: 0;
            overflow-y: hidden;
            transition: all 0.2s ease;
            z-index: 40;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            border-radius: 6px;
            z-index: 50;
            opacity: 0;

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: #535353;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                background: #ededed;
            }

            .item-box {
                padding: 10px 12px;
                min-height: 40px;
                line-height: 22px;
                font-size: 14px;
                border-top: 1px solid #f3f3f3;
                word-break: break-all;

                &:first-child {
                    border-top: 0;
                }

                &:hover {
                    cursor: pointer;
                    background: #f9f9f9;
                }
            }

            .empty {
                text-align: center;
                line-height: 40px;
                color: #888;
                font-size: 13px;
            }

            &.unfold {
                max-height: 200px;
                z-index: 20;
                overflow-y: auto;
                overflow-x: hidden;
                opacity: 1;
            }
        }

        > .unfold {
            border-color: #b3b3b3;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            z-index: 20;
        }
    }
}
</style>
